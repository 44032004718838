import styled, { css } from "styled-components";
import { rem } from "polished";


// Components
import { Card, Heading } from "hooly-ui-kit";

export const PaddingCard = {
  padding: `${rem("16px")} 0 ${rem("24px")} 0`,
}

const boldTest = css`
font-weight: bold;
`;

export const StyledSubTitle = styled.div`
  margin-top: ${rem("48px")};

  h5 { font-weight: bold; }
`;

export const WrapperBackButton = styled.div`
  margin-right: 24px;
`;

export const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

/*************+*************+*************+*************+*************+*************
 * Primary Card Detail Information Executive
 **************+***************************+***************************+************/
export const ExecutiveCardWrapper = styled(Card)`
  display: flex;
  align-items: center;
  border-radius: ${rem("32px")};
  margin: ${rem("16px")} 0;
  padding: 0px;
  position: relative;
  justify-content: space-between;

`;

export const ExecutivePersonalData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${rem("32px")};
  padding: ${rem("32px")};
`;

export const ExecutivePersonalDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${rem("32px")};
  padding: ${rem("32px")};
`;

export const ExecutiveCardThumb = styled.img`
  margin-right: ${rem("32px")};
`;

export const ExecutiveLeadWom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${(p) => p.theme.fonts.primaryFont};
  ${boldTest}
  height: 100%;
  padding: ${rem("32px")};

`;

export const StyledCardPrimary = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  height: 100%;
  font-family: ${(p) => p.theme.fonts.primaryFont};
  padding: ${rem("20px")} 0 ${rem("20px")} 0;
  margin: ${rem("10px")} 0 ${rem("10px")} 0;
  ${boldTest}
`;

export const StyledTitleCardPrimary = styled.div`
  background: linear-gradient(45deg, rgb(238, 50, 248) 0%, rgb(230, 76, 79) 100%);
  border-radius: ${rem("8px")};
  color: rgb(255, 255, 255);
  padding: ${rem("2px")} ${rem("20px")} ${rem("2px")} ${rem("20px")};
  font-size: ${rem("28px")};
`;

export const StyledNumberCardPrimary = styled.div`
  font-size: ${rem("120px")};
  text-align: center;
  color: rgb(20, 20, 20);
`;

export const NullData = styled.div`
  height: ${rem("20px")};
  width: ${rem("100px")};
  background-color: #D8D8D8;
  border-color: #979797;
  border-radius: 10px;
`;

export const StyledCardSecond = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  height: 100%;

  font-family: ${(p) => p.theme.fonts.primaryFont};
  margin: ${rem("10px")} 0 ${rem("10px")} 0;
  ${boldTest}
`;

export const StyledTitleCardSecond = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: ${rem("18px")};
  text-align: center;
  padding: 0 ${rem("10px")} 0 ${rem("10px")};
  ${boldTest}
`;

export const StyledNumberCardSecond = styled.div`
  font-size: ${rem("64px")};
  text-align: center;
  color: rgb(20, 20, 20);
  margin-top: ${rem("20px")}
  ${boldTest}
`;

export const TimeDetailPaginatorContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

p {
  font-weight: 700;
  color: #6236ff;
  font-size: ${rem("15px")};
}
`;

/* Card Status And Card Management */

export const StyledCardStatusManagementContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${rem("20px")};

  h4 {
    font-size: ${rem("22px")} !important;
    ${boldTest}
  }

  h5 {
    font-size: ${rem("19px")} !important;
    ${boldTest}
  }
`;

export const StyledDetailStateContainer = styled.div`
  width: 100%;
  margin-top: ${rem("17px")};
  margin-bottom: ${rem("5px")};
`;

export const StyledSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledProgressbarContainer = styled.div`
  position: relative;
  margin-top: ${rem("5px")};
  margin-bottom: ${rem("10px")};
`;

export const BackgroundProgressbar = styled.div`
  position: absolute;
  height: ${rem("9px")};
  border-radius: ${rem("8px")};
  background: rgb(238, 238, 238);
  width: 100%;
`;

export const GradientProgressbar = styled.div`
  position: absolute;
  height: ${rem("9px")};
  border-radius: ${rem("8px")};
  background: ${props => props.detail === 'status' ?
    "linear-gradient(45deg, rgb(238, 50, 248) 0%, rgb(230, 76, 79) 100%)"
    : "linear-gradient(45deg, rgb(117, 232, 238) 0%, rgb(27, 150, 241) 100%)"} ;
  width: ${props => props.percentage || 0}%;
`;

export const TimeDetail = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  i {
    margin-right: ${rem("8px")};
  }
`;

export const WrapperMenuOptions = styled.div`
  margin-right: ${rem("32px")};
`;

export const ExecutiveManagementContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;