import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";

// Libraries
import { Card } from "hooly-ui-kit";

// Components
import { Skeleton } from "../../../components";

const RecordingCardSkeletonCard = styled(Card)`
  margin: ${rem("16px")} 0;
  padding: ${rem("26px")};
`;

export default function RecordingCardSkeleton(props) {
  return (
    <RecordingCardSkeletonCard>
      <Row>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
      </Row>
    </RecordingCardSkeletonCard>
  );
}
