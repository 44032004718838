const INITIAL_STATE = {
        department: {}
};

const departmentReducer = (state = INITIAL_STATE, action) => {
    if (action.type === 'SAVE_DEPARTMENT') {
        return {
            ...state,
            department: action.payload,
        };
    }
    return state;
};

export default departmentReducer;