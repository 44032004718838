import { rem } from "polished";
import styled from "styled-components";
import { Card} from "hooly-ui-kit/build/lib";
import { Col } from "react-grid-system";
import SearchBar from "../../components/SearchBar/SearchBar";

export const WrapperMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: intial;
    text-align: center;
`;

export const WrapperSearchMenu = styled.div`
    width: 100%;
`;

export const SearchBarValidator = styled(SearchBar)`
    background-color: rgb(245, 242, 255);
`;

export const WrapperUserData = styled(Card)`
    border-radius: ${rem("32px")};
    padding: ${rem("32px")};
    width: 50%;
`;

export const ColData = styled(Col)`
`;

export const TextCard = styled.div`
    font-weight: bold; 
`;

export const WrapperResults = styled.div`
    margin: ${rem("80px")};

    display: flex;
    justify-content: center;
    width: 80%;
`;

export const WrapperValidator = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: ${rem("80px")};
`;

export const WrapperLoader = styled.div`
    width: 0 !important;
    position: relative;
`;
