import React, { Component } from "react";
import { Route } from "react-router";

// Components
import { SignIn, CreatePassword, ChangePassword } from "./components";

// Styles
import {
  LoginWrapper,
  LoginPaneLeft,
  LoginPaneRight,
  LoginCard
} from "./styles";


// Assets
import logo from "../../assets/images/hooly_logo.svg";

// Utilities
import {
  removeBatchFromStorage,
} from "../../utils";

export default class Login extends Component {
  componentDidMount() {
    // Clear storage
    removeBatchFromStorage(
      [
        "hooly-cognito-session",
        "isLoged"
      ]
    );
  }


  render() {
    return (
        <LoginWrapper>
          <LoginPaneLeft>
            <img src={logo} alt="Hooly" />
            <p>
              Con nuestra herramienta aumentarás tu <br /> productividad y podrás
              ayudar a nuestros clientes <br /> en su proceso de cambio
            </p>
          </LoginPaneLeft>

          <LoginPaneRight>

            <Route path={"/login"} exact component={SignIn} />
            <Route path={"/login/create-password"} component={CreatePassword} />
            <Route path={"/login/change-password"} component={ChangePassword} />

          </LoginPaneRight>
    
    </LoginWrapper>
    );
  }
}
