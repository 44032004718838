import React, { useState, useEffect } from "react";

// Components
import { CodeSent, SuccessfulChange, ChangePasswordForm } from "./components";

import {
    LoginCard,
} from "../../styles";

const ChangePassword = props => {

    const [changePasswordValues, setChangePasswordValues] = useState({
        username: "",
        email: "",
        changeStep: 0
    });

    // componentDidMount with hook
    useEffect(() => {
        const paramsString = props.location.search;
        const params = new URLSearchParams(paramsString);

        setChangePasswordValues({
            ...changePasswordValues,
            username: params.get('username'),
            email: params.get('email')
        });

    }, []);

    const onChangeSteps = step => {
        setChangePasswordValues({ ...changePasswordValues, changeStep: step });
    }

    const onBackLogin = () => {
        props.history.push("/login");
    }

    return (
        <LoginCard>
            <React.Fragment>
                
                {changePasswordValues.changeStep === 0 && (
                    <CodeSent
                        email={changePasswordValues.email}
                        changeStep={step => onChangeSteps(step)}
                        history={props.history}
                    />
                )}

                {changePasswordValues.changeStep === 1 && (
                    <ChangePasswordForm
                        email={changePasswordValues.email}
                        username={changePasswordValues.username}
                        changeStep={step => onChangeSteps(step)}
                    />
                )}
                
                {changePasswordValues.changeStep === 2 && (
                    <SuccessfulChange
                        backLogin={() => onBackLogin()}
                    />
                )}

            </React.Fragment>
        </LoginCard>
    );
}

export default ChangePassword;