import React, { Component } from "react";
import { Row, Col } from "react-grid-system";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import Pusher from "pusher-js";
import { jwtDecode } from "jwt-js-decode";

// Components
import { Paginator, InnerViewLayout, Loader } from "../../components";
import { Heading, SearchBar, Options, Informative } from "hooly-ui-kit";
import LiveCard from "./components/LiveCard/LiveCard";

// Assets
import rayo from './../../assets/images/Rayo.svg';

// Styles
import {
  LivePaginatorContainer,
  LiveContainerCenter
} from "./styles/LiveStyles";

// Services
import {
  getAllExecutivesStatus,
  getSearchExecutives
} from "./../../services/index";

const itemsPerPage = 6;

const menuOptions = [
  {
    displayText: "Todos",
    value: "Todos"
  },
  {
    displayText: "Descanso",
    value: "Descanso"
  },
  {
    displayText: "Almuerzo",
    value: "Almuerzo"
  },
  {
    displayText: "Gestión Administrativa",
    value: "Gestión Administrativa"
  },
  {
    displayText: "Capacitación • Reunión",
    value: "Capacitación • Reunión"
  },
  {
    displayText: "Cierre Lead",
    value: "Cierre Lead"
  },
  {
    displayText: "Ready",
    value: "Ready"
  }
];

export default class Live extends Component {
  constructor(props) {
    super(props);

    this.state = {
      executives: [],

      totalPages: 0,
      totalElementPage: 0,
      totalElementCount: 0,

      currentPage: this.props.match.params.page ? parseInt(this.props.match.params.page) : 1,

      currentMenuOption: "Todos",

      loadingExecutives: true,
      searchLoadingExecutives: false
    };

    // Create pusher connection

    /**
     * SUBJECTS FOR HANDLING PARAMETERS
     */
    this.input$ = new BehaviorSubject("");
    this.paginator$ = new BehaviorSubject(this.state.currentPage);
    this.options$ = new BehaviorSubject("Todos");

    // Pusher
    this.pusher = new Pusher("8425f9cbf408df4e7241", {
      cluster: "mt1",
      encrypted: true
    });

    const nameChannel = "hooly-live-channel-" + process.env.REACT_APP_BACKOFFICE_ENVIRONMENT;
    this.channel = this.pusher.subscribe(nameChannel);
  }

  componentDidMount() {
    // Create skeleton loader
    // Bring status from database (PAGINATED)
    // RxJS
    const { token } = JSON.parse(localStorage.getItem("hooly-cognito-session"));
    const jwt = jwtDecode(token);
    const username = jwt.payload["username"];
    this.queryParamsCombination$ = combineLatest(
      this.input$,
      this.paginator$,
      this.options$
    )
      .pipe(
        tap(([inputValues, paginator, options]) =>
          // inputValues == not loadingExecutives 
          this.setState(
            {
              loadingExecutives: inputValues ? false : true,
              searchLoadingExecutives: inputValues ? true : false
            }
          )
        ),
        debounceTime(500)
      )
      .subscribe(([inputValues, paginator, options]) => {
        if (inputValues || options || paginator) {
          getSearchExecutives(
            inputValues,
            options,
            paginator,
            itemsPerPage,
            username
          ).then(succ => {
            const {
              executives,
              total_element_page,
              total_element_count,
              total_pages
            } = succ.data;

            this.setState({
              executives,
              totalPages: total_pages,
              currentPage: paginator,
              totalElementPage: total_element_page,
              totalElementCount: total_element_count,
              loadingExecutives: false,
              searchLoadingExecutives: false
            });
          });
        } else {
          // Load All Executives
          getAllExecutivesStatus(paginator, itemsPerPage, username).then(succ => {
            const {
              executives,
              total_element_page,
              total_element_count,
              total_pages
            } = succ.data;
            this.setState({
              executives: executives,
              currentPage: paginator,
              totalPages: total_pages,
              totalElementPage: total_element_page,
              totalElementCount: total_element_count,
              loadingExecutives: false,
              searchLoadingExecutives: false
            });
          });
        }
      });

    // Pusher
    this.channel.bind("event-mongo-live-status-update", succ => {
      let updatedExecutive = this.state.executives.filter(
        executive => executive["_id"] === succ.documentKey["_id"]
      )[0];

      updatedExecutive = {
        ...updatedExecutive,
        ...succ.updateDescription.updatedFields
      };

      const executives = this.state.executives.map(executive => {
        if (executive["_id"] === updatedExecutive["_id"]) {
          return updatedExecutive;
        } else {
          return executive;
        }
      });

      this.setState({ executives });
    });

  }

  componentWillUnmount() {
    /**
     * KILL ALL ASYNC AND RXJS OPERATIONS
     */
    this.queryParamsCombination$.unsubscribe();
    this.input$.unsubscribe();
    this.options$.unsubscribe();
    this.paginator$.unsubscribe();
  }

  /**
   * When a paginator page is clicked, change the currentPage property of the state in order
   * to modify the Subject value and re-render the paginator with the new page
   */
  onPageClicked = pageNumber => {
    this.setState({ currentPage: pageNumber }, () =>
      this.paginator$.next(pageNumber)
    );
  };

  render() {
    const {
      executives,
      currentPage,
      totalPages,
      totalElementCount,
      currentMenuOption,
      loadingExecutives,
      searchLoadingExecutives
    } = this.state;

    return (
      <InnerViewLayout>
        {loadingExecutives && (<Loader />)}

        <InnerViewLayout.SearchbarAndOptions>
          <Heading type="H2">Live</Heading>
          <InnerViewLayout.SearchAndOptionsWrapper>
            <SearchBar
              className="Searchbar"
              placeholder="Buscar nombre ejecutivo"
              onChange={value => {
                if (value) {
                  this.input$.next(value);
                } else {
                  this.input$.next(value);
                  this.paginator$.next(1);
                }
              }}
            />
            <Options
              className="Options"
              menuOptions={menuOptions}
              selectedOption={currentMenuOption}
              onOptionClicked={option =>
                this.setState({ currentMenuOption: option }, () =>
                  this.options$.next(option)
                )
              }
            />
          </InnerViewLayout.SearchAndOptionsWrapper>
        </InnerViewLayout.SearchbarAndOptions>

        {/* Show Loader || Show List Cards Executives */}
        {searchLoadingExecutives && (
          <Loader />
        ) || (
            <Row>
              {executives.map(exe => (
                <Col key={exe["_id"]} sm={4}>
                  <LiveCard executiveStatus={exe} />
                </Col>
              ))}
            </Row>
        )}

        {/* Not Found Executives || Show Paginator*/}
        {executives.length == 0 && !loadingExecutives && (
          <LiveContainerCenter>
            <Informative image={rayo} title="Ups! no hay resultados para tu búsqueda 😢" />
          </LiveContainerCenter>
        ) || (
            <LivePaginatorContainer>
              <p>{`${currentPage * itemsPerPage -
                (itemsPerPage - 1)} a ${currentPage *
                itemsPerPage} de ${totalElementCount} Ejecutivos`}</p>
              <Paginator
                totalPages={totalPages}
                currentPage={this.state.currentPage}
                beforePressed={newPage => this.onPageClicked(newPage)}
                followingPressed={newPage => this.onPageClicked(newPage)}
              />
            </LivePaginatorContainer>
        )}
      </InnerViewLayout>
    );
  }
}
