import React, { Component } from 'react';
import posed, { PoseGroup } from 'react-pose';
import { AudioPlayer } from 'hooly-ui-kit';
import styled from 'styled-components';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

// Actions
import { closeAudioPlayer } from '../../redux/actions'

// Styles
export const AudioPlayerGlobalWrapper = styled.div`
  top: 0;
  width: 100%;
  p {
    font-weight: 800;
    color: #000000;
  };
`;

const AudioPlayerGlobalWrapperPosed = posed(AudioPlayerGlobalWrapper)({
    enter: { y: 0, opacity: 1 },
    exit: { y: -100, opacity: 0 }
});

function AudioPlayerComponent({ isDeployed, url, phoneNumber, closeAudioPlayer, recordingId }) {
    const phoneNumberFormatted = `+${phoneNumber.replace(/\s+/, "")}`;

    return (
        <PoseGroup>
            {isDeployed && (
                <AudioPlayerGlobalWrapperPosed key={recordingId}>
                    <AudioPlayer
                        phoneNumber={phoneNumberFormatted}
                        audioToPlay={url}
                        onClose={closeAudioPlayer}
                    />
                </AudioPlayerGlobalWrapperPosed>
            )}
        </PoseGroup>
    );
}

const mapDispatchToProps = dispatch => bindActionCreators({ closeAudioPlayer }, dispatch);

export default connect(null, mapDispatchToProps)(AudioPlayerComponent);
