import React from  "react";
import { Heading, Button, Input } from "hooly-ui";

const SuccessfulChange = ({ backLogin }) => {

    return (
        <React.Fragment>
        <div>
            <Heading.H3>
                Se ha realizado <br />
                exitosamente el cambio <br />
                de contraseña! 🥳
    </Heading.H3>
            <Button.Loader
                text="Iniciar sesion"
                size="full"
                variant="primary"
                style={{fontWeight: "800"}}
                onClick={() => backLogin()}
            />
        </div>
    </React.Fragment>
    )
}

export default SuccessfulChange;
