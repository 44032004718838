import React, { Component } from "react";
import moment from "moment";
import { debounceTime, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import times from "lodash/times";


// styles
import {
  WrapperBackButton,
  TimeDetailPaginatorContainer,
  NullData
} from "../../components/ExecutiveDetail/styles/GeneralDetailCardStyles";
import { InnerViewLayout, PseudoTable, Paginator, Loader } from "../../../../components";

// Components
import { Heading, Back, PseudoDatePicker } from "hooly-ui-kit";
import TimeDetailCard from "../../components/ExecutiveDetail/components/TimeDetailCard";
import ExecutiveCardSkeleton from "../../components/ExecutiveCardSkeleton/ExecutiveCardSkeleton";

import { getDetailtConnectionStatus } from "../../../../services/index";

const headerOptions = [
  {
    title: "Fecha",
    function: null
  },
  {
    title: "Ready",
    function: null
  },
  {
    title: "Descanso",
    function: null
  },
  {
    title: "Almuerzo",
    function: null
  },
  {
    title: "Gestión Administrativa",
    function: null
  },
  {
    title: "Capacitación • Reunión",
    function: null
  }
];

const itemsPerPage = 6;

export default class ExecutiveStatusDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.match.params.username,
      executiveStatusDetail: [],

      totalPage: 0,
      totalElementCount: 0,

      currentPage: 1,

      datesFilter: [],

      loadingExecutiveStatusDetail: true
    }

    this.paginator$ = new BehaviorSubject(1);
  }

  getCurrentDay() {
    const today = moment();
    const todayBeginningOfDay = today.startOf("day").toDate();
    const todayEndOfDay = today.endOf("day").toDate();
    return [todayBeginningOfDay, todayEndOfDay];
  }

  componentDidMount() {
    this.paginator$.pipe(
      tap(() =>
        this.setState({
          loadingExecutiveStatusDetail: true
        })
      ),
      debounceTime(500)
    ).subscribe(async () => {
      const response = await this.handleGetAllTotalDates(this.state.datesFilter);
      this.setState({ ...response });
    })
  }

  async handleGetAllTotalDates(dates) {
    this.setState({ loadingStatistics: true, datesFilter: dates, loadingExecutiveStatusDetail: true });

    const first_date = moment(dates[0]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
    const end_date = moment(dates[1]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"

    const response = await getDetailtConnectionStatus(first_date, end_date, this.state.username, this.state.currentPage, itemsPerPage)
      .catch(err => console.log(err));
    const data = response.data;

    return {
      totalPage: data.total_page,
      totalElementCount: data.total_element_count,
      executiveStatusDetail: data.result,
      loadingExecutiveStatusDetail: false
    };
  }

  /**
   * When a paginator page is clicked, change the currentPage property of the state in order
   * to modify the Subject value and re-render the paginator with the new page
   */
  onPageClicked = pageNumber => {
    this.setState({ currentPage: pageNumber }, () =>
      this.paginator$.next(pageNumber)
    );
  };

  render() {
    const { history } = this.props;
    const { currentPage, totalElementCount, totalPage, executiveStatusDetail, loadingExecutiveStatusDetail } = this.state;

    const maxDate = moment().toDate();
    const minDate = moment("20190101", "YYYYMMDD").toDate();

    return (
      <InnerViewLayout>
        <InnerViewLayout.SearchbarAndOptions>
          <WrapperBackButton>
            <Back onClick={() => history.goBack()} size="m" />
          </WrapperBackButton>
          <Heading type="H2">Estados</Heading>
          <PseudoDatePicker 
            onDateSelected={async range => {
              this.state.currentPage = 1;
              const response = await this.handleGetAllTotalDates(range);
              this.setState({ ...response });
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </InnerViewLayout.SearchbarAndOptions>
        <PseudoTable headers={headerOptions}>

          {loadingExecutiveStatusDetail && (times(itemsPerPage, num => <ExecutiveCardSkeleton key={num} numColumns={headerOptions.length} />)
          ) || (
              <React.Fragment>
                {executiveStatusDetail.map((value, index) => (
                  <TimeDetailCard
                    key={index}
                    timeSet={value} />
                ))}

                <TimeDetailPaginatorContainer>
                  <p>{`${currentPage * itemsPerPage -
                    (itemsPerPage - 1)} a ${currentPage *
                    itemsPerPage} de ${totalElementCount} Resultados`}</p>
                  <Paginator
                    totalPages={totalPage}
                    currentPage={currentPage}
                    beforePressed={newPage => this.onPageClicked(newPage)}
                    followingPressed={newPage => this.onPageClicked(newPage)}
                  />
                </TimeDetailPaginatorContainer>
              </React.Fragment>
            )}
        </PseudoTable>
      </InnerViewLayout>
    );
  }
};
