import { AXIOS_C } from "./cusAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:5000";
const PATH = "twilio-recordings";

/**
 * Get all recordings
 */
export const getAllRecordings = async (department_id, department_name) => {

  return AXIOS_C.get(`${ENDPOINT}/${PATH}/record`,{
    params: {
      department_name: department_name,
      department_id: department_id
    }
  }
  );
};

/**
 * Retrieves a recording by recording_id
 * @param {string | number} recordingId
 */
export const getRecordingByRecordingId = async recordingId => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/${recordingId}`);
};

/**
 * Retrieves recordings by lead_id
 * @param {string} lead_id
 */
export const getRecordingsByLeadId = async (lead_id, hooly_username) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/record`, {
    params: {
      lead_id,
      hooly_username
    }
  });
}

/**
 *
 * @param {string} phoneNumber
 * @param {string} execId
 */
export const queryRecordingsWithParams = async (
  phoneNumberOrExecId,
  option,
  page,
  department_name,
  department_id
) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/record`, {
    params: {
      phone_or_exec_id: phoneNumberOrExecId,
      option,
      page,department_name: department_name,
      department_id: department_id
    }
  });
};

/**
 *
 * @param {String} objectName
 */
export const getSignedUrl = async objectName => {
  return AXIOS_C.post(`${ENDPOINT}/${PATH}/record/get_signed_url`, {
    object_name: objectName
  });
};
