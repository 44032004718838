import { combineReducers } from "redux";

// Reducers
import backdropReducer from "./reducers/backdropReducer";
import lateralMenuReducer from "./reducers/lateralMenuReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import audioPlayerReducer from "./reducers/audioPlayerReducer";
import departmentReducer from "./reducers/departmentReducer";
import departmentPickerReducer from "./reducers/selectDepartmentsReducer";

export default combineReducers({
  backdrop: backdropReducer,
  lateralMenu: lateralMenuReducer,
  notifications: notificationsReducer,
  audioPlayer: audioPlayerReducer,
  department: departmentReducer,
  selectDepartments: departmentPickerReducer
});
