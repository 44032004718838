import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { motion, AnimatePresence } from "framer-motion";

// Utils
import { isOutOfViewport } from "../../utils";

/**
 * Checks if the list of elements is within the viewport bounds.
 * As current design, it is probable that it will go beyond the right margin.
 * If it's out of the viewport on the right side, it will adjust the right position
 * as 'right: 0'
 * @param {*} element - The List Element
 */
const checkIfOutOfBoundsAndChangeStyleIfNecessary = element => {
  if (isOutOfViewport(element.current).right) {
    element.current.style.right = 0;
  }
};

// Styles
const ThreeDotsOptionsWrapper = styled.div`
  position: relative;
`;

const ThreeDotsOptionsThreeDots = styled.i`
  font-size: ${rem("8px")};
  color: #6236ff;
  cursor: pointer;
`;

const ThreeDotsOptionsList = motion.custom(styled.ul`
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: red;
  padding: ${rem("16px")};
  background: #6236ff;
  border-radius: ${rem("8px")};
  color: white;
  margin-top: 16px;
  width: auto;
  z-index: 1;
  min-width: ${rem("180px")};
  outline: none;
`);

const ThreeDotsOptionsListItem = styled.li`
  color: white;
  font-weight: 800;
  cursor: pointer;
  padding: ${rem("10px")};

  &:hover {
    color: #6236ff;
    background: #e8e1ff;
    border-radius: ${rem("8px")};
  }
`;

class ThreeDotsOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { optionsDeployed: false };

    this.optionsList = React.createRef();
  }

  render() {
    const { options } = this.props;
    const { optionsDeployed } = this.state;

    return (
      <ThreeDotsOptionsWrapper className="ThreeDotsOptions">
        <ThreeDotsOptionsThreeDots
          className="hly-three-dots"
          onClick={() =>
            this.setState({ optionsDeployed: !optionsDeployed }, () => {
              this.optionsList.current.focus();
            })
          }
        />
        <AnimatePresence>
          {optionsDeployed && (
            <ThreeDotsOptionsList
              onAnimationStart={() =>
                checkIfOutOfBoundsAndChangeStyleIfNecessary(this.optionsList)
              }
              ref={this.optionsList}
              element={this.optionsList}
              onBlur={() => this.setState({ optionsDeployed: false })}
              tabIndex="0"
              key="ThreeOptions"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{
                duration: 0.2,
                type: "tween"
              }}
            >
              {options &&
                options.map(option => (
                  <ThreeDotsOptionsListItem
                    key={option.text}
                    onClick={() =>
                      this.setState({ optionsDeployed: false }, () =>
                        option.action()
                      )
                    }
                  >
                    {option.text}
                  </ThreeDotsOptionsListItem>
                ))}
            </ThreeDotsOptionsList>
          )}
        </AnimatePresence>
      </ThreeDotsOptionsWrapper>
    );
  }
}
export default ThreeDotsOptions;
