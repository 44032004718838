import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import moment from "moment";
import { msToTime } from "./../../../../../utils/formatters/time";

// Components

// Libraries
import { Card } from "hooly-ui-kit";
import { NullData } from "../styles/GeneralDetailCardStyles";

// Styles
const TimeDetail = styled(Card)`
  margin: ${rem("16px")} 0 !important;
  font-weight: 900;
  box-shadow: 0 ${rem("10px")} ${rem("26px")} 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: ${rem("26px")};

  &:hover {
    box-shadow: 0 ${rem("14px")} ${rem("28px")} rgba(0, 0, 0, 0.25),
      0 ${rem("10px")} ${rem("10px")} rgba(0, 0, 0, 0.22);
  }
`;

const TimeDetailCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export default function TimeDetailCard(props) {
  const date = props.timeSet.date;
  delete props.timeSet.date;

  const data = [];

  for (const key in props.timeSet) {
    data.push(props.timeSet[key]);
  }

  return (
    <TimeDetail>
      <Row sm={12}>
        <TimeDetailCol>
          {date}
        </TimeDetailCol>
        {data && data.map((value, index) => (
          <TimeDetailCol key={index}>
            {value != 0 ? msToTime(value) : <NullData />}
          </TimeDetailCol>
        )
        )}
      </Row>
    </TimeDetail>
  );
}
