import React, { useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';

// Services
import { changePasswordFirstTime } from "./../../../../services/authServ";

// Validators
import {
  passwordRules,
  allPasswordErrorsArray
} from "./../../../../utils/validators";

// Styles
import {
  LoginCard
} from "../../styles";

import { Input as InputKit } from "hooly-ui-kit";
import { Heading, Button } from "hooly-ui-kit";

import AsPasswordInput from "./../../../../utils/HOC/passwordInput";

import { MultipleErrors } from '../../.../../../../components';

const InputPassword = AsPasswordInput(InputKit);

const CreatePassword = props => {

  const [ 
    createPasswordValues, setCreatePasswordValues,
  ] = useState({
    username: "",
    session: "",
  });

  let [ 
    showCreatePassword, setShowCreatePassword
  ] = useState({
    showCreatePassword: true
  });

  let errors_array = [];

  const {
    setValue,
    setError,
    errors,
    handleSubmit,
    control,
    getValues,
    isSubmitting,
    formState
  } = useForm({
    criteriaMode: "all",
    mode: "all",
  });


  // componentDidMount with hook
  useEffect(() => {
    const paramsString = props.location.search;
    const params = new URLSearchParams(paramsString); 

    setCreatePasswordValues({ 
        ...createPasswordValues,  
        username: params.get('username'),
        session: JSON.parse(localStorage.getItem("hooly-session_pass")),
    });

    setShowCreatePassword({
      showCreatePassword: true
    });

    setError("newPassword");
  }, [])

  // Convert form errors to array errors
  if (errors.newPassword) {
    errors_array = [];
    if (errors.newPassword.types) {
        for (var [key, value] of Object.entries(errors.newPassword.types)) {
            errors_array.push(value);
        }
    } else {
        // React-Hook-Form lib hasn't initial validation so we set manually the first errors
        errors_array = allPasswordErrorsArray;
    }
  }

  const onSubmitCreatePassword = async () => {
    try {
        const CreatePassword = await changePasswordFirstTime(createPasswordValues.username, createPasswordValues.session, getValues('newPassword'));
        showCreatePassword.showCreatePassword = false;
    } 
    catch (error) {
        console.log("onSubmitCreatePassword", error);
    }
  }

  const goToLogin = () => {
    const path = '/login';
    props.history.push(path);
  }

  // components to show
  const formNewPassword = props => {
    return (
      <React.Fragment>

        <Heading type="H3">
          Ahora debes crear tu 
          <br/>
          nueva contraseña
        </Heading>

        <form onSubmit={handleSubmit(onSubmitCreatePassword)}>

          <Controller
            control={control}
            name="newPassword"
            rules={passwordRules}
            defaultValue=""
            render={({ onChange, onBlur, value, name, newPassword }) => (
                
                <InputPassword
                    label={"Escribe tu nueva contraseña"}
                    type="password"
                    value={value}
                    onBlur={onBlur}
                    onChange={(event) =>
                        setValue(
                            "newPassword",
                            event.target.value,
                            { shouldValidate: true }
                        )
                    }
                />
                
            )}
          />

          <Controller
            control={control}
            defaultValue=""
            name="newPasswordConfirm"
            rules={{
                validate: (value) => getValues('newPassword') === value || "Las contraseñas no coinciden",
                required: {value: true, message: "Las contraseñas no coinciden"}
            }}
            render={({ onChange, onBlur, value, name }) => (
                <InputPassword
                    label={"Confirma tu nueva contraseña"}
                    type="password"
                    value={value}
                    onBlur={onBlur}
                    onChange={(event) =>
                        setValue(
                            "newPasswordConfirm",
                            event.target.value,
                            { shouldValidate: true }
                        )
                    }

                    touched={formState.touched?.newPasswordConfirm}
                    error={errors.newPasswordConfirm?.message}
                />
            )}
          />

          <MultipleErrors validationErrors={errors_array} />

          <Button
            type="loader"
            text="Acceder"
            size="full"
            variant="primary"
            disabled={!formState.isValid}
            loading={formState.isSubmitting.toString()}
          />
        </form>
      </React.Fragment>
    );
  }

  const successNewPassword = props => {
    return (
      <React.Fragment>
        <Heading type="H3">
          Tu nueva contraseña ha sido 
          <br/>
          creada con éxito! 🥳


        </Heading>

        <Button
          text="Iniciar sesión"
          variant="primary"
          type="submit"
          onClick={goToLogin}
        />

      </React.Fragment>
    );
  }



  return (
    <React.Fragment>
      
      <LoginCard>
        { showCreatePassword.showCreatePassword ? formNewPassword(props) : successNewPassword(props) }
      </LoginCard>

    </React.Fragment>
  );
}

export default CreatePassword;