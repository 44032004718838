import styled, { css } from "styled-components";
import { rem } from "polished";

export const WrapperHeading = styled.div`
  display: row;
  justify-content: space-between;
`;

export const PaginatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 800;
    color: #6236ff;
    font-size: ${rem("14px")};
  }
`;

export const ContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;