import { AXIOS_C } from "./cusAxios";
import moment from "moment";
import { msToTime } from "./../utils/formatters/time"

const ENDPOINT = process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:5000";
const PATH = "punchclock";

/**
 * Get First Call For User (TimeStamp First Call)
 * @returns TimeStamp result
 */
const getFirstCallForUser = async (start_date, end_date, username) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_first_call_for_user`, {
    params: { start_date, end_date, username }
  });
};

/**
 * Get Total Scheduled (TimeStamp End Call)
 * @returns TimeStamp result
 */
const getEndCallForUser = async (start_date, end_date, username) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_end_call_for_user`, {
    params: { start_date, end_date, username }
  });
};

/**
 * Get Total Connection Time (TimeStamp Total Connection)
 * @returns TimeStamp result
 */
const getTotalConnectionTimeForUser = async (start_date, end_date, username) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_total_connection_time`, {
    params: { start_date, end_date, username }
  });
};

/**
 * Get Total Detail Connection Time (TimeStamp Total Connection)
 * @returns TimeStamp result
 */
export const getDetailtConnectionTime = async (start_date, end_date, username, page, limit) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_connection_details`, {
    params: { start_date, end_date, username, page, limit }
  });
};

/**
 * Get Total Detail Ststus Connection Time (TimeStamp Total Connection)
 * @returns TimeStamp result
 */
export const getDetailtConnectionStatus = async (start_date, end_date, username, page, limit) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_connection_status_details`, {
    params: { start_date, end_date, username, page, limit }
  });
};

/**
 * Get Total Status Connection Time
 * @returns String result
 */
export const getStatusConnectionTime = async (start_date, end_date, username, search_old_state, search_new_state) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_connection_status`, {
  params: { start_date, end_date, username, search_old_state, search_new_state }
  });
};

/******************************************************************************************
 *                                        Custom Request
*******************************************************************************************/

export const getConnectionTime = async (start_date, end_date, username) => {
  let time_detail = [
    { id: 0, title: "Hora Inicio Conexión" },
    { id: 1, title: "Hora Fin Conexión" },
    { id: 2, title: "Tiempo Total en Llamadas" }
  ];

  let listPromise = [];
  // If stard_dte equals end_date, search now day
  if (start_date == end_date) {
    listPromise[0] = getFirstCallForUser(start_date, end_date, username);
    listPromise[1] = getEndCallForUser(start_date, end_date, username);
    listPromise[2] = getTotalConnectionTimeForUser(start_date, end_date, username);
  // Other case, search only total connection time 
  } else {
    listPromise[0] = getTotalConnectionTimeForUser(start_date, end_date, username);
  }

  return Promise.all(listPromise)
    .then(responses => {
      if (responses.length == 1) {
        time_detail[2].value = msToTime(responses[0].data);
      } else {
        time_detail[0].value = responses[0].data != 0 ? moment.utc(responses[0].data).format("HH:mm:ss") : undefined;
        time_detail[1].value = responses[1].data != 0 ? moment.utc(responses[1].data).format("HH:mm:ss") : undefined;
        time_detail[2].value = msToTime(responses[2].data);
      }

      return time_detail;
    })
    .catch(err => {
      console.log(err);
      return time_detail;
    });
};

export const getAllTimeStatusTotal = async (first_date, end_date, username) => {
  let time_status_total = null;
  let time_status = [
    { id: 0, title: "Ready", old_state: "Ready", new_state: "Cierre Lead" },
    { id: 1, title: "Descanso", old_state: "Descanso", new_state: "" },
    { id: 2, title: "Almuerzo", old_state: "Almuerzo", new_state: "" },
    { id: 3, title: "Cierre Lead", old_state: "Cierre Lead", new_state: "Ready" },
    { id: 4, title: "Gestión Administrativa", old_state: "Gestión Administrativa", new_state: "" },
    { id: 5, title: "Capacitación • Reunión", old_state: "Capacitación • Reunión", new_state: "" }
  ];

  return Promise.all([
    getStatusConnectionTime(first_date, end_date, username, time_status[0].old_state, time_status[0].new_state),
    getStatusConnectionTime(first_date, end_date, username, time_status[1].old_state, time_status[1].new_state),
    getStatusConnectionTime(first_date, end_date, username, time_status[2].old_state, time_status[2].new_state),
    getStatusConnectionTime(first_date, end_date, username, time_status[3].old_state, time_status[3].new_state),
    getStatusConnectionTime(first_date, end_date, username, time_status[4].old_state, time_status[4].new_state),
    getStatusConnectionTime(first_date, end_date, username, time_status[5].old_state, time_status[5].new_state)
  ])
    .then(responses => {
      const length = responses.length;
      // Sum Total And Set Value For Status
      for (let index = 0; index < length; index++) {
        time_status_total += responses[index].data;
      }

      for (let index = 0; index < length; index++) {
        time_status[index].value = msToTime(responses[index].data);
        time_status[index].percentage = time_status_total != 0 ? ((responses[index].data * 100) / time_status_total) : 0;
      }

      return {
        time_status_total: moment.utc(time_status_total).format("HH:mm:ss"),
        time_status
      };
    })
    .catch(err => console.log(err));
};
