import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import posed, { PoseGroup } from "react-pose";

// Components
import { Notification } from "../../components";

// Styles
import { NotificationsHolder } from "./styles";

// Actions
import { removeNotification } from "../../redux/actions";

// Services
import { NotificationsService } from "../../services";

const NotificationPoseWrapper = posed.div({
  enter: { y: 0, opacity: 1 },
  exit: { y: -50, opacity: 0 }
});

let NotificationsObservable;

class NotificationHolder extends Component {
  onRegisterNotificationForRemoval = id => {
    setTimeout(() => {
      this.props.removeNotification(id);
    }, 3000);
  };

  componentDidMount() {
    // Subscribe to Notifications registrations.
    // Use the recently registered Notification in order to delete it in the near future
    NotificationsObservable = NotificationsService.getNotifications().subscribe(
      Notification => {
        setTimeout(() => {
          this.props.removeNotification(Notification.id);
        }, 4000);
      }
    );
  }

  componentWillUnmount() {
    // Unsubscribe to prevent memory leaks
    NotificationsObservable.unsubscribe();
  }

  render() {
    const { notifications } = this.props.notifications;
    const { removeNotification } = this.props;

    return (
      <NotificationsHolder>
        <PoseGroup>
          {notifications.length > 0 &&
            notifications.map(notification => (
              <NotificationPoseWrapper key={notification.id}>
                <Notification
                  type={notification.type}
                  text={notification.text}
                  key={notification.text}
                  onNotificationClose={() =>
                    removeNotification(notification.id)
                  }
                  registerNotificationForRemoval={() =>
                    this.onRegisterNotificationForRemoval(notification.id)
                  }
                />
              </NotificationPoseWrapper>
            ))}
        </PoseGroup>
      </NotificationsHolder>
    );
  }
}

const mapStateToProps = state => {
  const { notifications } = state;

  return {
    notifications
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeNotification
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHolder);
