import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import moment from "moment";

// Components

// Libraries
import { Card } from "hooly-ui-kit";


// Styles
const RecordingCardCard = styled(Card)`
  margin: ${rem("16px")} 0 !important;
  font-weight: 900;
  box-shadow: 0 ${rem("10px")} ${rem("26px")} 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: ${rem("26px")};

  &:hover {
    box-shadow: 0 ${rem("14px")} ${rem("28px")} rgba(0, 0, 0, 0.25),
      0 ${rem("10px")} ${rem("10px")} rgba(0, 0, 0, 0.22);
  }
`;

const RecordingCardCol = styled(Col)`
  display: flex;
  align-items: center;
`;

const RecordingCardButtonCol = styled.div`
    position: absolute;
    right: 0;
`;

const RecordingCardActionButton = styled.i`
  font-size: 22px;
  cursor: pointer;
  color: #6236ff;
  padding: ${rem("12px")}
`;


export default function RecordingDetailCard(props) {
    const {
        recording_start_time,
        hooly_username,
        recording_sid,
        lead_id
    } = props.recording;

    const {
        onAudioPlay,
        onDownloadClicked,
        isThisPlaying,
        onAudioPaused
    } = props;
    
    return (
        <RecordingCardCard>
            <Row sm={2}>
                <RecordingCardCol>
                    {`${moment
                        .utc(recording_start_time)
                        .local()
                        .format("DD-MM-YYYY")} · ${moment
                            .utc(recording_start_time)
                            .local()
                            .format("HH:mm")}`}
                </RecordingCardCol>
                <RecordingCardCol>
                    <RecordingCardButtonCol>
                        {isThisPlaying ? (
                            <RecordingCardActionButton
                                className="hly-paused"
                                onClick={() =>
                                    onAudioPaused(
                                        `${hooly_username}_${lead_id}_${recording_sid}.wav`
                                    )
                                }
                            />
                        ) : (
                                <RecordingCardActionButton
                                    className="hly-play"
                                    onClick={() =>
                                        onAudioPlay(`${hooly_username}_${lead_id}_${recording_sid}.wav`)
                                    }
                                />
                            )}
                        <RecordingCardActionButton
                            className="hly-download"
                            onClick={() =>
                                onDownloadClicked(
                                    `${hooly_username}_${lead_id}_${recording_sid}.wav`
                                )
                            }
                        />
                    </RecordingCardButtonCol>

                </RecordingCardCol>
            </Row>
        </RecordingCardCard>
    );
}
