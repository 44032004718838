import React from "react";

import {
    CodeSentText
  } from "../../../../styles";

import { Heading, Button } from "hooly-ui";

import { WrapperHeadContent, Arrow, RutHolder, ChangePasswordWrapper } from "../../../../styles";

const CodeSent = ({ email, changeStep, history }) => {
    return (
        <React.Fragment>
          <div>
              <WrapperHeadContent>
                  <Arrow
                      className="hly-left-arrow"
                      onClick={history.goBack}
                  />
                  <Heading.H4 style={CodeSentText}>
                      Te hemos enviado un código de <br/>
                      verificación al email <br/>
                      {email} <br/> 
                      Llegará en unos minutos, revísalo <br/>
                      y copia el código para crear una <br/>
                      nueva contraseña.
              </Heading.H4>
              </WrapperHeadContent>


              <Button.Filled
                  text="Siguiente"
                  size="full"
                  variant="primary"
                  style={{fontWeight: "800"}}
                  onClick={() => changeStep(1)}
              />
          </div>
        </React.Fragment>
    )
}

export default CodeSent;