import React from "react";
import PropTypes from "prop-types";

// Styles
import {
  DashboardMenuWrapper,
  DashboardMenuItem
} from "./styles/DashboardMenuStyles";

const DashboardMenu = props => {
  const { menuItems, menuItemClicked, location } = props;

  return (
    <DashboardMenuWrapper>
      {menuItems.map(item => (
        <DashboardMenuItem
          onClick={() => menuItemClicked(item.menuItemPath)}
          key={item.menuItemName}
          active={item.menuItemPath === location}
        >
          <i className={item.menuIcon} />
          {item.menuItemName}
        </DashboardMenuItem>
      ))}
    </DashboardMenuWrapper>
  );
};

DashboardMenu.propTypes = {
  menuItems: PropTypes.array.isRequired
};

export default DashboardMenu;
