import styled, { css } from "styled-components";
import { rem } from "polished";
import {ReactComponent as info_icon} from '../../../../../assets/images/info_icon.svg'

// Components
import { Card, Heading, Informative } from "hooly-ui-kit";
import React from "react";

export const PaddingCard = {
  padding: `${rem("16px")} 0 ${rem("24px")} 0`,
}

export const ExecutiveFormField = styled.div`
    padding: 1.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    line-height: 2rem;
    font-size: 1.5rem;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    z-index: 5;
    box-sizing: border-box;
    color: #CAD3DE !important;
    border: 0.1875rem solid #CAD3DE !important;
    border-radius: 0.5rem;
    margin: 1rem 0;
    position: relative;
    font-family: Marmelat;
    -webkit-transition: 0.1s ease all;
    transition: 0.1s ease all;
`;

export const ExecutiveFormFieldLabel = styled.label`
    top: 0;
    bottom: 0;
    left: 1rem;
    font-size: 1rem;
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-110%);
    -ms-transform: translateY(-110%);
    transform: translateY(-110%);
    line-height: 2.625rem;
    font-weight: 900;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
    color: #CAD3DE !important;
`;

export const ExecutiveTypeProfile = styled.label`
    height: 32px;
    left: ${rem('0px')};
    position: relative;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;

    color: #141414;
    `

export const ContainerInfoAuditor = styled.div`
`

export const LabelCard = styled.div`
    weight: 400;
    height: 24px;
    left: 20px;
    position: relative;
    
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height:24px;
    color: #141414`


export const DialogueGlobe = styled.div`
    visibility: hidden;
    z-index: 100;
    position: absolute;

    
    width: ${rem('300px')};
    height: ${rem('73px')};
    left: -170px;
    top: ${rem('-60px')};
    border-radius: 10px;
    padding: ${rem('10px')};

    font-weight: ${rem('300px')}; 
    font-family: Nunito;
    font-size: ${rem('16px')};
    font-style: normal;
    text-align: center;
    line-height: 20px;

    color: transparent;
    background: transparent;
    background-align:center;
    transition: visibility 0.2s, color 0.2s, background 0.2s, width 0.2s ease-in-out;

    :after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 80%;
        margin-left: -10px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent;
        transition: border 0.2s border-color 0.2s ease-in-out;
        
    }`

export const InfoAuditorImg = styled(info_icon)`
    cursor: pointer;
    width: ${rem('20px')};
    height: ${rem('20px')};
    margin-left: ${rem('11px')};
    margin-top: ${rem('14px')};

     {
        &:hover + ${DialogueGlobe}{
        visibility: visible;
        color: #FFFFFF;
        background: rgb(0,0,0,1);
        background-position: absolute;
        text-align: center;
        width: ${rem('340px')};
        height: ${rem('40px')};
        &:after {
            border-color: #112455 transparent transparent transparent;
        }
        }
    } `