import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import moment from "moment";
// Components

// Libraries
import { Card, Button } from "hooly-ui-kit";
import { phoneNumberFormatter } from "../../../../../utils/formatters/PhoneNumberFormatter";

// Styles
const ManagementDetail = styled(Card)`
   margin: ${rem("24px")} ${rem("0px")};
   padding: ${rem("0px")} ${rem("16px")};
  box-shadow: 0 ${rem("10px")} ${rem("26px")} 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 ${rem("14px")} ${rem("28px")} rgba(0, 0, 0, 0.25),
      0 ${rem("10px")} ${rem("10px")} rgba(0, 0, 0, 0.22);
  }
    
  .Col {
    display: flex;
    align-items: center;

    &__Actions {
      display: flex;
      justify-content: flex-end;
    }
  }
`;

const RecordingsCardButton = styled(Button)`
    background: #ffffff;
    color: #6236ff;
    border: 2px solid #6236ff;
    box-shadow: 0 18px 40px -12px rgba(38, 0, 172, 0.35);
    margin: ${rem("15px")};
    &:hover {
        background: #E8E1FF;
  }
`;

const ManagementDetailCardText = styled.span`
  font-weight: 800;
`;

export default function ManagementDetailCard(props) {

    const { date, phone_number, id, selecction } = props.timeSet;

    const phone_number_formatted = phoneNumberFormatter(phone_number);

    const date_formatted = moment(date).format('DD-MM-YYYY');

    return (
        <ManagementDetail>
            <Row>
                <Col className="Col">
                    <ManagementDetailCardText>{date_formatted}</ManagementDetailCardText>
                </Col>
                <Col className="Col">
                    <ManagementDetailCardText>{phone_number_formatted}</ManagementDetailCardText>
                </Col>
                <Col className="Col">
                    <ManagementDetailCardText>
                        {selecction}
                    </ManagementDetailCardText>
                </Col>
                <Col className="Col__Actions">
                    <RecordingsCardButton
                        text="Ver Detalle"
                        size="s"
                        onClick={() => props.recordings(id, phone_number_formatted)}
                    />
                </Col>

            </Row>
        </ManagementDetail>
    );
}
