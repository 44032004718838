export const passwordRules = {
  validate: {
    hasLower: (value) => /[a-z]/.test(value) || "Una letra minúscula",
    hasUpper: (value) => /[A-Z]/.test(value) || "Una letra mayúscula",
    hasNumber: (value) => /\d/.test(value) || "Un número",
    hasSpecialCharacter: (value) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value) || "Un carácter especial ej. $ & *",
    minLength: (value) => value.length >= 8 || "8 caracteres como mínimo"
  }
};

export const allPasswordErrorsArray = [
  "8 caracteres como mínimo",
  "Una letra minúscula",
  "Una letra mayúscula",
  "Un número",
  "Un carácter especial ej. $ & *"
];