import voca from "voca";

export const formatRut = run => {
  const rut = rutClean(String(run));

  if (rut.length <= 1) {
    return rut;
  }

  let result = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
  for (let i = 4; i < rut.length; i += 3) {
    result = `${rut.slice(-3 - i, -i)}.${result}`;
  }

  return result;
};

export const formatRutWithoutDots = run => {
  const rut = rutClean(String(run));

  let result = rut;

  result = voca.insert(result, "-", result.length - 1);

  return result;
};

export const formatRutWithoutDotsHyphenAndVerif = run => {
  let rut = formatRutWithoutDots(run);
  rut = rut.slice(0, -1);

  return Number(rutClean(rut));
};

/**
 * Returns RUT without dots and hyphen
 */
export const removeDotsAndHyphen = run => {
  return rutClean(run);
};

const rutClean = value => {
  return typeof value === "string"
    ? value.replace(/[^0-9kK]+/g, "").toUpperCase()
    : "";
};
