import { rem } from "polished";
import styled from "styled-components";

export const StyledSearchBar = styled.div`
  border-radius: ${rem("18px")};
  background: #f8f8f8;
  border: none;
  display: flex;
  padding: ${rem("14px")};
`;

export const StyledSearchBarIcon = styled.div`
  padding: ${rem("0px")} ${rem("16px")};
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: ${rem("24px")};
    color: #000;
  }
`;

export const StyledSearchBarInput = styled.input`
  background: transparent;
  border: none;
  font-size: ${rem("16px")};
  line-height: ${rem("24px")};
  outline: none;
  width: 100%;

  &:placeholder {
    color: #000;
    font-weight: 100;
  }
`;
