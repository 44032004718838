import styled from "styled-components";
import { rem } from "polished";

export const DashboardInformationMessage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${rem("8px")};
  position: absolute;
  max-width: ${rem("550px")};
  height: ${rem("34px")};
  left: ${rem("0px")};
  top: ${rem("0px")};
  background: #000000;
  border-radius: ${rem("8px")};
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: ${rem("0px")} ${rem("0px")};
  opacity: 1;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(${rem("100px")}) translateY(${rem("80px")});
  z-index:1;
`;

export const DashboardTextInformationMessage = styled.p`
  height: ${rem("20px")};
  left: ${rem("8px")};
  top: ${rem("14px")};
  font-style: normal;
  font-weight: 500;
  font-size: ${rem("16px")};
  line-height: ${rem("20px")};
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: ${rem("0px")} ${rem("8px")};
  white-space: nowrap;
`;

export const DashboardTagDepartment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${rem("4px")} ${rem("8px")}
  opacity: 1;
  position: absolute;
  max-width: ${rem("245px")};
  height: ${rem("28px")};
  left: ${rem("10px")};
  top: ${rem("120px")};
  z-index:1;
  background: #6236FF;
  border: ${rem("1px")} solid #8F7FFC;
  box-sizing: border-box;
  border-radius: ${rem("8px")};
`;

export const DashboardTagText = styled.p`
  height: ${rem("20px")};
  left: ${rem("8px")};
  top: ${rem("4px")};
  font-style: normal;
  font-weight: 500;
  font-size: ${rem("16px")};
  line-height: ${rem("20px")};
  color: #FFFFFF;
  order: 0;
  flex-grow: 0;
  margin: ${rem("0px")} ${rem("8px")};
  white-space: nowrap;
`;


export const DashBoardInformationDepartmentHover = styled.div`
  &:hover {
    ${DashboardInformationMessage} {
      visibility: visible;
      opacity: 1;
      transform: translateY(${rem("60px")}) translateX(${rem("100px")});
    }
  }
`;

export const DashboardTriangle = styled.div`
  width: 0; 
  height: 0; 
  border-top: ${rem("8px")} solid #000000;
  border-left: ${rem("8px")} solid transparent;
  border-bottom: ${rem("8px")} solid transparent;
  border-right: ${rem("8px")} solid transparent;
  opacity: 1;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: translateX(${rem("110px")}) translateY(${rem("40px")});
  ${DashBoardInformationDepartmentHover}:hover & {
    visibility: visible;
    opacity: 1;
    transform: translateY(${rem("28px")}) translateX(${rem("110px")});
  }
`;



