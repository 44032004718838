import styled from "styled-components";
import { rem } from "polished";

import { Card } from "hooly-ui-kit";

const setNotificationBackgroundColor = props => {
  const { type } = props;

  return type === "ok" ? "#6236ff" : "#E74C3C";
};

const setNotificationGlow = props => {
  const { type } = props;

  return type === "ok"
    ? "0 10px 27px 0 #2600AC"
    : "0 2px 21px 0 rgba(231,76,60,0.44)";
};

export const Notification = styled(Card)`
  border-radius: ${rem("16px")};
  margin: ${rem("16px")} 0;
  font-size: ${rem("20px")};
  background: ${props => setNotificationBackgroundColor(props)};
  box-shadow: ${props => setNotificationGlow(props)};
  font-weight: 900;
  position: relative;
  z-index: 20;
`;

export const NotificationText = styled.span`
  color: white;
  text-align: center;
  display: block;
`;

export const NotificationClose = styled.i`
  font-size: ${rem("30px")};
  height: ${rem("30px")};
  width: ${rem("30px")};
  position: absolute;
  right: ${rem("16px")};
  top: 0;
  bottom: 0;
  margin: auto 0;
  opacity: 0.29;
  color: white;
  cursor: pointer;
  display: block;
`;
