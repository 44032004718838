import lodash from 'lodash';

export const phoneNumberFormatter = phone_number => {
    try {
        let formatted_phone_number = lodash.trim(String(phone_number))

        if (phone_number.length !== 12) {
            switch (phone_number.length) {
                case 11:
                    formatted_phone_number = "+" + formatted_phone_number
                    return formatted_phone_number
                case 10:
                    formatted_phone_number = "+5" + formatted_phone_number
                    return formatted_phone_number
                case 9:
                    formatted_phone_number = "+56" + formatted_phone_number;
                    return formatted_phone_number;
                case 8:
                    formatted_phone_number = "+569" + formatted_phone_number
                    return formatted_phone_number
            }
        }
        return formatted_phone_number;
    }
    catch (e) {
        return "";
    }
}