import styled, { css } from "styled-components";
import { rem } from "polished";

// Componets
import { SearchBar, Options } from "hooly-ui-kit";

export const LivePaginatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 800;
    color: #6236ff;
    font-size: ${rem("14px")};
  }
`;

export const LiveContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

export const marginTop = css`
  margin-top: 15%;
`
