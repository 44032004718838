const INITIAL_STATE = {
    departments: []
};

const departmentPickerReducer = (state = INITIAL_STATE, action) => {
if (action.type === 'SELECT_DEPARTMENTS') {
    return {
        ...state,
        departments: action.payload,
    };
}
return state;
};

export default departmentPickerReducer;