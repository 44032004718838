import {
    Recordings,
    General,
    Live,
    Executives,
    Validator,
  } from "../../containers";

export const adminMenu = [
  {
    menuItemName: "General",
    menuItemPath: "/dashboard/general",
    component: General,
  },
  {
    menuItemName: "Live",
    menuItemPath: "/dashboard/live",
    component: Live,
  },
  {
    menuItemName: "Ejecutivos",
    menuItemPath: "/dashboard/ejecutivos/1",
    component: Executives,
  },
  {
    menuItemName: "Grabaciones",
    menuItemPath: "/dashboard/grabaciones",
    component: Recordings,
  },
  {
    menuItemName: "Validador",
    menuItemPath: "/dashboard/validador",
    component: Validator,
  },
];

export const auditMenu = [
    {
      menuItemName: "Grabaciones",
      menuItemPath: "/dashboard/grabaciones",
      component: Recordings,
    }
  ];