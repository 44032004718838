import React from "react";

import { Notification, NotificationText, NotificationClose } from "./styles";

function returnNotificationText(text) {
  return { __html: `${text}` };
}

const ReverseNotification = ({ type, text, onNotificationClose }) => {
  return (
    <Notification type={type}>
      <NotificationText
        dangerouslySetInnerHTML={returnNotificationText(text)}
      />
      <NotificationClose
        className="hly-circle-cross"
        onClick={() => onNotificationClose()}
      />
    </Notification>
  );
};

export default ReverseNotification;
