import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";

// Libraries
import { Card, Button } from "hooly-ui-kit";

// Utils
import { formatRut } from "../../../utils";

// Styles
const RecordingCardCard = styled(Card)`
  margin: ${rem("16px")} 0 !important;
  font-weight: 900;
  box-shadow: 0 ${rem("10px")} ${rem("26px")} 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: ${rem("26px")};

  &:hover {
    box-shadow: 0 ${rem("14px")} ${rem("28px")} rgba(0, 0, 0, 0.25),
      0 ${rem("10px")} ${rem("10px")} rgba(0, 0, 0, 0.22);
  }
`;

const RecordingCardCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RecordingCardActionButton = styled.i`
  font-size: 22px;
  cursor: pointer;
  color: #6236ff;
`;

const RecordingsCardButton = styled(Button)`
    background: ${p => p.isPlaying ? "#6236ff" : "#ffffff"} !important;
    color: ${p => p.isPlaying ? "#ffffff" : "#6236ff"};
    border: 2px solid #6236ff;
    margin: 0;
    margin-right: ${rem("10px")};
    box-shadow: none;
    &:hover {
        background: #E8E1FF !important;
    }
`;

// Utilitarian Functions
const secondsToHms = d => {
  d = Number(d);

  // Hours, minutes and seconds
  var hrs = ~~(d / 3600);
  var mins = ~~((d % 3600) / 60);
  var secs = ~~d % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
};

function RecordingCard(props) {

  const {
    hooly_username,
    phone_number,
    recording_duration,
    recording_start_time,
    recording_sid,
    lead_id,
    id
  } = props.recording;

  const {
    onAudioPlay,
    onDownloadClicked,
    recordingId
  } = props;

  const isPlaying = recordingId === id ? true : false;

  return (
    <RecordingCardCard>
      <Row sm={6}>
        <RecordingCardCol>{formatRut(hooly_username)}</RecordingCardCol>
        <RecordingCardCol>{`+${phone_number}`}</RecordingCardCol>
        <RecordingCardCol>
          {`${moment
            .utc(recording_start_time)
            .local()
            .format("DD-MM-YYYY")} · ${moment
              .utc(recording_start_time)
              .local()
              .format("HH:mm")}`}
        </RecordingCardCol>
        <RecordingCardCol>{secondsToHms(recording_duration - 1)}</RecordingCardCol>
        <RecordingCardCol>
          <RecordingsCardButton
            text={isPlaying ? "Reproduciendo" : "Reproducir"}
            isPlaying={isPlaying}
            disabled={isPlaying}
            size="s"
            onClick={() =>
              onAudioPlay(`${hooly_username}_${lead_id}_${recording_sid}.wav`)
            }
          />

          <RecordingCardActionButton
            className="hly-download"
            onClick={() =>
              onDownloadClicked(
                `${hooly_username}_${lead_id}_${recording_sid}.wav`
              )
            }
          />
        </RecordingCardCol>
      </Row>
    </RecordingCardCard>
  );
}

const mapStateToProps = state => state.audioPlayer;

export default connect(mapStateToProps, null)(RecordingCard);