import styled from "styled-components";
import { rem } from "polished";

export const DashboardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const DashboardPane = styled.section`
  width: 100%;
`;

export const DashboardLeftPane = styled(DashboardPane)`
  height: 100%;
  width: ${rem("330px")};
  background-color: #4706c8;
  padding: ${rem("24px")};
  box-sizing: border-box;
  position: relative;

  .formOne,
  .formTwo {
    position: absolute;
    left: 0;
  }

  .formOne {
    top: 6%;
  }

  .formTwo {
    bottom: 10%;
  }
`;

export const DashboardRightPane = styled(DashboardPane)`
  overflow-y: scroll;
  box-sizing: content-box;
`;

export const DashboardLogo = styled.img`
  text-align: center;
  display: block;
  margin: auto;
`;

export const WrapperPanelList = styled.div`
  padding: ${rem("32px")};
  height: 100%;
  box-sizing: border-box;
`;

export const WrapperAudioPlayerComponent = styled.div``;
