import { AXIOS_C } from "./cusAxios";

const ENDPOINT =
  process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL;
const PATH = "live";

/**
 * Get all executives
 */
export const getAllExecutivesStatus = async (limit) => {
  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_all_executives`, {
    params: {
      limit
    }
  });
};

/**
 * Get search executives
 */
export const getSearchExecutives = async (search, options, page, limit, username) => {

  return AXIOS_C.get(`${ENDPOINT}/${PATH}/get_search_executives`, {
    params: {
      search,
      options,
      page,
      limit,
      username
    }
  });
};
