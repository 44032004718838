import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";

// Actions
import {
  openLateralMenu,
  closeLateralMenu,
  openBackdrop,
  closeBackdrop,
  addNotification,
} from "../../redux/actions";

// Assets
import rayo from "./../../assets/images/Rayo.svg";
import { jwtDecode } from "jwt-js-decode";
// Components
import {
  InnerViewLayout,
  SquareButton,
  Paginator,
  Loader,
} from "../../components";
import { SearchBar, Heading, Informative } from "hooly-ui-kit";
import { Row, Col } from "react-grid-system";
import ExecutiveCard from "./components/ExecutiveCard/ExecutiveCard";
import CreateEditExecutive from "./components/CreateEditExecutive/CreateEditExecutive";
import DeleteExecutive from "./components/DeleteExecutive/DeleteExecutive";

import { PaginatorContainer, ContainerCenter } from "./styles/ExecutivesStyles";

// Services
import {
  createUser,
  updateUser,
  deactivateUser,
  searchUsers,
} from "../../services";

const itemsPerPage = 6;

class Executives extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      executives: [],
      executive: {},
      totalPages: 0,
      totalElementPage: 0,
      totalElementCount: 0,

      currentPage: this.props.match.params.page
        ? parseInt(this.props.match.params.page)
        : 1,

      loadingExecutives: false,
      searchLoadingExecutives: false,

      stepNumber: 0,
      update: false,
    };

    /**
     * SUBJECTS FOR HANDLING PARAMETERS
     */
    this.input$ = new BehaviorSubject("");
    this.paginator$ = new BehaviorSubject(this.state.currentPage);
  }

  componentDidMount() {
    this.handleStartOrUpdateDataUsers();
  }

  componentDidUpdate() {
    if (this.state.update) {
      this.handleStartOrUpdateDataUsers();
      this.setState({ update: false });
    }
  }
  handleStartOrUpdateDataUsers() {
    /**
     * CALCULATE EXECUTIVES FILTER OUTCOME THROUGH SUBJECTS
     */
    const { token } = JSON.parse(localStorage.getItem("hooly-cognito-session"));
    const jwt = jwtDecode(token);
    const username = jwt.payload["username"];
    this.queryParamsCombination$ = combineLatest(this.input$, this.paginator$)
      .pipe(
        tap(([inputValues, _]) =>
          this.setState({
            loadingExecutives: inputValues ? false : true,
            searchLoadingExecutives: inputValues ? true : false,
          })
        ),
        debounceTime(500)
      )
      .subscribe(([inputValues, paginator]) => {
        if (inputValues || paginator) {
          searchUsers(inputValues, paginator, itemsPerPage, username).then(
            (succ) => {
              const {
                executives,
                total_element_page,
                total_element_count,
                total_pages,
              } = succ.data;

              this.setState({
                executives,
                totalPages: total_pages,
                totalElementPage: total_element_page,
                totalElementCount: total_element_count,
                loadingExecutives: false,
                searchLoadingExecutives: false,
              });
            }
          );
        }
      });
  }

  componentWillUnmount() {
    /**
     * KILL ALL ASYNC AND RXJS OPERATIONS
     */
    this.input$.unsubscribe();
    this.paginator$.unsubscribe();
  }

  /**
   * When a paginator page is clicked, change the currentPage property of the state in order
   * to modify the Subject value and re-render the paginator with the new page
   */
  onPageClicked = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const path = `/dashboard/ejecutivos/${pageNumber}`;
      this.props.history.push(path);
      this.paginator$.next(pageNumber);
    });
  };

  onDetailClicked = (executive) => {
    const path = `/dashboard/ejecutivos/detail/${executive.username}`;
    this.props.history.push(path, { executive });
  };

  onCreateUser = () => {
    this.props.openBackdrop();

    this.props.openLateralMenu(
      <CreateEditExecutive
        method="create"
        text="Ingresa los datos personales para crear una nueva cuenta de usuario"
        buttonText="Guardar"
        createOrEditPromiseBasedFunction={createUser}
        createOrEditCompleted={(data) => this.onCreateOrEditCompleted(data)}
        executive={{
          username: "",
          firstName: "",
          lastName: "",
          rut: "",
          phone: "",
          role: "",
          gender: "",
          email: "",
          password: "",
          department:''
        }}
      />,
      "Crear usuario"
    );
  };

  onEditUser = (executive) => {
    this.props.openBackdrop();

    this.props.openLateralMenu(
      <CreateEditExecutive
        method="update"
        text="Acá puedes modificar los datos personales de la cuenta de usuario"
        buttonText="Guardar"
        executive={executive}
        createOrEditPromiseBasedFunction={updateUser}
        createOrEditCompleted={(data) => this.onCreateOrEditCompleted(data)}
      />,
      "Editar usuario"
    );
  };

  onDeleteUser = (executive) => {
    this.props.openBackdrop();

    this.props.openLateralMenu(
      <DeleteExecutive
        executive={executive}
        confirmDeleteExecutive={(executiveUsername) =>
          this.onConfirmDeleteExecutive(executiveUsername)
        }
        dismissDeleteExecutive={() => {
          this.props.closeBackdrop();
          this.props.closeLateralMenu();
        }}
      />,
      "Eliminar usuario"
    );
  };

  onConfirmDeleteExecutive = (executiveUsername) => {
    deactivateUser(executiveUsername)
      .then((succ) => {
        this.props.closeLateralMenu();
        this.props.closeBackdrop();

        this.props.addNotification({
          id: executiveUsername,
          text: `Usuario eliminado`,
          type: "ok",
        });
      })
      .catch((err) => console.log(err));
  };

  onCreateOrEditCompleted = (response) => {
    if (response.status >= 200 || response.status <= 299) {
      if (response.data.method === "updateUser") {
        this.props.closeLateralMenu();
        this.props.closeBackdrop();

        this.props.addNotification({
          id: response.data.method,
          text: `Usuario editado`,
          type: "ok",
        });
        this.setState({ update: true });
      }

      if (response.data.method === "createUser") {
        this.props.closeLateralMenu();
        this.props.closeBackdrop();

        this.props.addNotification({
          id: response.data.method,
          text: `Usuario creado`,
          type: "ok",
        });
        this.setState({ update: true });
      }
    } else {
      this.props.addNotification({
        id: "error",
        text: `Ya existe un usuario con este RUT 🤕.`,
        type: "notOk",
      });
    }
  };

  render() {
    const {
      executives,
      currentPage,
      totalPages,
      totalElementCount,
      loadingExecutives,
      searchLoadingExecutives,
      stepNumber,
    } = this.state;

    return (
      <InnerViewLayout>
        {loadingExecutives && <Loader />}

        {stepNumber === 0 && (
          <React.Fragment>
            <InnerViewLayout.SearchbarAndOptions>
              <Heading type="H2">Ejecutivos</Heading>
              <InnerViewLayout.SearchAndOptionsWrapper>
                <SearchBar
                  className="Searchbar"
                  placeholder="Buscar nombre ejecutivo"
                  onChange={(value) => {
                    if (value) {
                      this.input$.next(value);
                    } else {
                      this.input$.next(value);
                      this.paginator$.next(1);
                    }
                  }}
                />
                <SquareButton
                  text="Crear usuario"
                  onClick={() => this.onCreateUser()}
                />
              </InnerViewLayout.SearchAndOptionsWrapper>
            </InnerViewLayout.SearchbarAndOptions>

            {(searchLoadingExecutives && <Loader />) || (
              <Row>
                {executives &&
                  executives.map((executive) => (
                    <Col key={executive.username} sm={4}>
                      <ExecutiveCard
                        executive={executive}
                        editUser={(executive) => this.onEditUser(executive)}
                        deleteUser={(executive) => this.onDeleteUser(executive)}
                        onSelectedDetailExecutive={(executive) =>
                          this.onDetailClicked(executive)
                        }
                      />
                    </Col>
                  ))}
              </Row>
            )}

            {executives.length === 0 && !loadingExecutives && (
              <ContainerCenter>
                <Informative
                  image={rayo}
                  title="Ups! no hay resultados para tu búsqueda 😢"
                />
              </ContainerCenter>
            )}

            {!loadingExecutives && (
              <PaginatorContainer>
                <p>{`${currentPage * itemsPerPage - (itemsPerPage - 1)} a ${currentPage * itemsPerPage
                  } de ${totalElementCount} Ejecutivos`}</p>
                <Paginator
                  totalPages={totalPages}
                  currentPage={this.state.currentPage}
                  beforePressed={(newPage) => this.onPageClicked(newPage)}
                  followingPressed={(newPage) => this.onPageClicked(newPage)}
                />
              </PaginatorContainer>
            )}
          </React.Fragment>
        )}
      </InnerViewLayout>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openLateralMenu,
      closeLateralMenu,
      openBackdrop,
      closeBackdrop,
      addNotification,
    },
    dispatch
  );
const mapStateToProps = (state) => state.department;
export default connect(mapStateToProps, mapDispatchToProps)(Executives);
