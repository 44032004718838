import React from "react";

// Styles
import loader from "../../assets/images/hooly-loader.svg";
import { LoaderContainer, LoaderContainerImg, LoaderImage } from "./styles/LoaderStyles";

const Loader = () => {
  return (
    <LoaderContainer>
      <LoaderContainerImg>
        <LoaderImage src={loader}/>
      </LoaderContainerImg>
    </LoaderContainer>
  );
};

export default Loader;
