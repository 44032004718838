import axios from "axios";
import { AXIOS_C } from "./cusAxios";
import { removeBatchFromStorage } from "../utils";

import {
  removeDotsAndHyphen
} from "../utils";

const ENDPOINT = process.env.REACT_APP_HOOLY_API_URL;
const PATH = "auth";

export let isLoged = !localStorage.getItem('hooly-cognito-session') ? false : true;

/**
 *
 * @function
 * @async 
 * 
 * Get a valid session from Hooly auth ms - cognito integration
 *
 * @param {string} username - Username (RUT) of the person to be authenticated
 * @param {string} password - The password of the user to be authenticated
 * @returns {Promise} An axios promise
 */
export const logIn = async (username, password) => {
  // const formatUsername = removeDotsAndHyphen(username);

  return await AXIOS_C.post(
    `${ENDPOINT}/${PATH}/session`, {
      username,
      password
    })
    .then(response => {
      localStorage.setItem(
        "hooly-cognito-session",
        JSON.stringify(response.data)
      );
      
      isLoged = true;

      return {
        status: response.status,
        messages: "success login",
        response: response.data
      }
    })
    // .catch(err => {
    //   console.log("Error Login: ", err);
      
    //   throw new Error(err.response.status);
    // });
};

/**
 *
 * @function
 * 
 * Delete a session from Hooly auth ms - cognito integration
 *
 * @returns {Promise} An axios promise
 */
export const logOut = () => {
  return AXIOS_C.delete(`${ENDPOINT}/${PATH}/session`)
  .then(response => {
    removeBatchFromStorage(["hooly-cognito-session"]);
    isLoged = false;
    return true;
  }).catch(err => {
      console.log("logOut Error", err);

      return false;
    });
};

export const changePasswordFirstTime = (username, session, newPassword) => {
  return axios.post(
    `${ENDPOINT}/auth/password/first_time`,
    {
      username,
      session,
      new_password: newPassword
    },
    {
      headers: {
        CustomOrigin: "Hooly"
      }
    }
  );
};
