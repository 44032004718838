import React from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Heading, Button } from "hooly-ui-kit";
import { LoaderButton } from "../../../../components";

// Styles
const DeleteExecutiveWrapper = styled.div`
  text-align: center;
`;

const DeleteExecutiveDisclaimer = styled.p`
  font-size: ${rem("24px")};
  text-align: center;
`;

const DeleteExecutiveButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const DeleteExecutive = ({
  executive,
  confirmDeleteExecutive,
  dismissDeleteExecutive
}) => {
  return (
    <React.Fragment>
      <DeleteExecutiveDisclaimer>
        ¿Estás seguro de que quieres eliminar esta cuenta de usuario?
      </DeleteExecutiveDisclaimer>
      <DeleteExecutiveWrapper>
        <Heading type="H4">{`${executive.first_name} ${executive.last_name}`}</Heading>
      </DeleteExecutiveWrapper>
      <DeleteExecutiveButtons>
        <LoaderButton
          loading={false}
          text="Si"
          onClick={() => confirmDeleteExecutive(executive.username)}
        />
        <Button text="No" type="secondary" onClick={() => dismissDeleteExecutive()} />
      </DeleteExecutiveButtons>
    </React.Fragment>
  );
};

export default DeleteExecutive;
