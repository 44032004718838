import axios from "axios";
import customAxios from "./customAxios";

const ENDPOINT = process.env.REACT_APP_HOOLY_API_URL;
const PATH = "auth";

/**
 * @param {string} username - Username (RUT) of the person to be authenticated
 * @param {string} password - The password of the user to be authenticated
 * @returns {Promise} An axios promise
 */
export const logIn = (username, password) => {
  return customAxios.post(
    `${ENDPOINT}/${PATH}/session`,
    {
      username,
      password
    },
    {
      headers: {
        CustomOrigin: "Hooly"
      }
    }
  );
};

export const logOut = async () => {
  return customAxios.delete(`${ENDPOINT}/${PATH}/session`);
};

export const refreshToken = () => {
  return axios({
    method: "post",
    url: `${ENDPOINT}/${PATH}/session/refresh_token`,
    data: {
      refresh_token: getRefreshToken()
    },
    headers: {
      CustomOrigin: "test"
    }
  });
}

function getRefreshToken() {
  return JSON.parse(localStorage.getItem("hooly-cognito-session")).refresh_token;
}