import styled, { css } from "styled-components";
import { rem } from "polished";
import { Card } from "hooly-ui-kit";

export const PaddingCard = {
  padding: `${rem("16px")} 0 ${rem("24px")} 0`,
}

const boldTest = css`
  font-weight: bold;
`;

export const StyledSubTitle : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  margin-top: ${rem("48px")};

  h5 { font-weight: bold; }
`;

export const StyledCardPrimary = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  height: 100%;

  font-family: ${(p: any) => p.theme.fonts.primaryFont};
  padding: ${rem("20px")} 0 ${rem("20px")} 0;
  margin: ${rem("10px")} 0 ${rem("10px")} 0;
  ${boldTest}
`;

export const StyledTitleCardPrimary : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  background: linear-gradient(45deg, rgb(238, 50, 248) 0%, rgb(230, 76, 79) 100%);
  border-radius: ${rem("8px")};
  color: rgb(255, 255, 255);
  padding: ${rem("2px")} ${rem("20px")} ${rem("2px")} ${rem("20px")};
  font-size: ${rem("28px")};
`;

export const StyledNumberCardPrimary : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  font-size: ${rem("120px")};
  text-align: center;
  color: rgb(20, 20, 20);
  margin-top: ${rem("15px")};
`;

export const StyledCardSecond = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: ${rem("32px")};
  height: 100%;

  font-family: ${(p: any) => p.theme.fonts.primaryFont};
  margin: ${rem("10px")} 0 ${rem("10px")} 0;
  ${boldTest}
`;

export const StyledTitleCardSecond : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-size: ${rem("18px")};
  text-align: center;
  padding: 0 ${rem("10px")} 0 ${rem("10px")};
  ${boldTest}
`;

export const StyledNumberCardSecond : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  font-size: ${rem("64px")};
  text-align: center;
  color: rgb(20, 20, 20);
  margin-top: ${rem("10px")};
  ${boldTest}
`;

export const WrapperHeading : React.SFC<React.HTMLProps<HTMLDivElement>> = styled.div`
  display: flex;
  justify-content: space-between;
`;