import styled from "styled-components";
import { rem } from "polished";

import { Card } from "hooly-ui-kit";

export const PaginatorButton = styled.button`
  background-color: #6236ff;
  padding: ${rem("8px")} ${rem("12px")};
  border: none;
  cursor: pointer;
  color: white;
  outline: none;

  &:disabled {
    background-color: rgba(98, 54, 255, 0.2);
    cursor: not-allowed;
  }
`;

export const PaginatorWrapper = styled(Card)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #efebff;
  padding: 0;
  background-color: transparent;
  box-shadow: none;

  border-top-left-radius: ${rem("18px")};
  border-bottom-left-radius: ${rem("18px")};

  border-top-right-radius: ${rem("18px")};
  border-bottom-right-radius: ${rem("18px")};

  overflow: hidden;
`;

export const PaginatorContent = styled.div`
  padding: ${rem("8px")} ${rem("16px")};
  font-weight: 800;
  color: #6236ff;
  font-size: ${rem("14px")};
  background-color: white;
  width: ${rem("58px")};
  text-align: center;
`;
