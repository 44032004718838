// hooks
import React from "react";
import moment from "moment";
import _ from "lodash";

// Components
import { InnerViewLayout, Loader } from "../../../components";
import { Col, Row } from "react-grid-system";
import { Heading, PseudoDatePicker, Back, Informative, Button } from "hooly-ui-kit";

// Services
import {
    getOpportunityHoolyCountByStatusAndRangeAndAlias,
    getUserByUsername,
    getConnectionTime,
    getAllTimeStatusTotal,
    getAllTotalDetails
} from "../../../services";

// styles
import {
    ExecutiveCardWrapper,
    ExecutivePersonalData,
    ExecutiveCardThumb,
    ExecutiveLeadWom,
    StyledTitleCardPrimary,
    StyledNumberCardPrimary,
    StyledCardSecond,
    StyledTitleCardSecond,
    StyledNumberCardSecond,
    StyledSubTitle,
    PaddingCard,
    WrapperBackButton,
    ContainerCenter,
    StyledCardStatusManagementContainer,
    StyledSpaceBetween,
    StyledDetailStateContainer,
    StyledProgressbarContainer,
    BackgroundProgressbar,
    GradientProgressbar,
    TimeDetail,
    NullData
} from "../components/ExecutiveDetail/styles/GeneralDetailCardStyles";

import styled from "styled-components";
import { rem } from "polished";

const ExecutiveContainerDetailt = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const ExecutiveCardButton = styled(Button)`
    background: #6236ff;
    box-shadow: 0 18px 40px -12px rgba(38, 0, 172, 0.35);
    margin-top: ${rem("40px")};
`;

const stage_name = "Ganada"

class ExecutiveDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.match.params.username,
            executive: {},
            totalWon: null,
            timeDetail: [],
            timeDetailStatus: {},

            // Control Component
            loading: false,
            error: false
        };
    }

    // Get "start" and "end" date of the current day
    getCurrentDay = () => {
        const today = moment();
        const todayBeginningOfDay = today.startOf("day").toDate();
        const todayEndOfDay = today.endOf("day").toDate();
        return [todayBeginningOfDay, todayEndOfDay];
    };

    getThumbByGender = gender => {
        if (gender === "M" || gender === "m") {
            return require("../../../assets/images/avatar-man.svg");
        } else {
            return require("../../../assets/images/avatar-girl.svg");
        }
    };

    onInnerPageDetailClicked = onPath => {
        const username = this.state.username;
        const path = `/dashboard/ejecutivos/detail/${username}/${onPath}`;
        this.props.history.push(path, { username });
    };

    async handleGetAllTotalDates(dates) {
        this.setState({ loading: true });
        const { username } = this.state;
        const onlyRun = username.substring(0, username.length - 1);

        const first_date = moment(dates[0]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
        const end_date = moment(dates[1]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"

        const responseGetExecutive = await getUserByUsername(username)
            .catch(() => {
                this.setState({ error: true });
            });


        const responseGetTotalWon = await getOpportunityHoolyCountByStatusAndRangeAndAlias(onlyRun, first_date, end_date, 'Ganada')
            .catch(() => {
                return 0;
            });

        const responseGetTimeDetailt = await getConnectionTime(first_date, end_date, username);

        const responseGetAllTimesStatusTotal = await getAllTimeStatusTotal(first_date, end_date, username);
        
        const details = await getAllTotalDetails(first_date, end_date, username);
      
        this.setState({
            executive: { ...responseGetExecutive.data.user },
            totalWon: responseGetTotalWon.data ? responseGetTotalWon.data.total : responseGetTotalWon,
            timeDetail: responseGetTimeDetailt,
            timeDetailStatus: responseGetAllTimesStatusTotal,
            totalDetailManagement: details ? details.management : [],
            total_management: details ? details.total_management : 0,
            loading: false
        });
    }

    async componentDidMount() {
        const currentDay = this.getCurrentDay();
        this.handleGetAllTotalDates(currentDay);
    }

    render() {
        const { history } = this.props;
        const { executive, username, timeDetail, timeDetailStatus, totalWon, loading, error, totalDetailManagement, total_management } = this.state;
        
        const maxDate = moment().toDate();
        const minDate = moment("20190101", "YYYYMMDD").toDate();
        
        return (
            <InnerViewLayout>
                <InnerViewLayout.SearchbarAndOptions>
                    <WrapperBackButton>
                        <Back onClick={() => history.goBack()} size="m" />
                    </WrapperBackButton>
                    <Heading type="H2">Detalle Ejecutivo</Heading>
                    <PseudoDatePicker 
                        onDateSelected={range => this.handleGetAllTotalDates(range)}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </InnerViewLayout.SearchbarAndOptions>

                {loading && <Loader />}
                {executive && (
                    <React.Fragment>
                        <ExecutiveCardWrapper>
                            <ExecutivePersonalData>
                                <ExecutiveCardThumb src={this.getThumbByGender(executive.gender)} />
                                <div>
                                    <Heading type="H3">
                                        {executive.first_name && executive.last_name ? (`${executive.first_name} ${executive.last_name}`) : <NullData />}
                                    </Heading>
                                    <Heading type="H5">
                                        {executive.phone ? executive.phone : <NullData />}
                                        <br />
                                        {executive.email ? executive.email : <NullData />}
                                    </Heading>
                                </div>
                            </ExecutivePersonalData>
                            <ExecutiveLeadWom>
                                <StyledTitleCardPrimary>Ganados</StyledTitleCardPrimary>
                                <StyledNumberCardPrimary>
                                    {totalWon}
                                </StyledNumberCardPrimary>
                            </ExecutiveLeadWom>
                        </ExecutiveCardWrapper>

                        <StyledSubTitle>
                            <Heading type="H5">Tiempo Conectado</Heading>
                        </StyledSubTitle>

                        <Row style={PaddingCard}>
                            {timeDetail.map(casilla => (
                                <Col sm={4} key={casilla.id}>
                                    <StyledCardSecond>
                                        <StyledTitleCardSecond>
                                            {casilla.title && (`${casilla.title}`) || <NullData />}
                                        </StyledTitleCardSecond>
                                        <StyledNumberCardSecond>
                                            {(casilla.value && casilla.value != undefined) && (`${casilla.value}`) || <NullData />}
                                        </StyledNumberCardSecond>
                                    </StyledCardSecond>
                                </Col>
                            ))}
                        </Row>

                        <ExecutiveContainerDetailt>
                            <ExecutiveCardButton
                                text="Ver detalle"
                                size="m"
                                onClick={() => this.onInnerPageDetailClicked("time-detail")}
                            />
                        </ExecutiveContainerDetailt>

                        {timeDetailStatus && totalDetailManagement && (
                            <Row>
                                <Col sm={6}>
                                    <StyledCardStatusManagementContainer>
                                        <StyledDetailStateContainer>
                                            <StyledSpaceBetween>
                                                <Heading type="H4">Estados</Heading>
                                                <Heading type="H4">Total: {timeDetailStatus.time_status_total}</Heading>
                                            </StyledSpaceBetween>
                                        </StyledDetailStateContainer>

                                        {timeDetailStatus.time_status && timeDetailStatus.time_status.map(casilla => (
                                            <StyledDetailStateContainer key={casilla.id}>
                                                <StyledSpaceBetween>
                                                    <Heading type="H5">{casilla.title}</Heading>
                                                    <TimeDetail>
                                                        <i className="hly-clock"></i>
                                                        <Heading type="H5">{casilla.value}</Heading>
                                                    </TimeDetail>
                                                </StyledSpaceBetween>
                                                <StyledProgressbarContainer>
                                                    <BackgroundProgressbar />
                                                    <GradientProgressbar percentage={casilla.percentage} detail='status' />
                                                </StyledProgressbarContainer>
                                            </StyledDetailStateContainer>
                                        ))}
                                        <ExecutiveContainerDetailt>
                                            <ExecutiveCardButton
                                                text="Ver detalle"
                                                size="s"
                                                onClick={() => this.onInnerPageDetailClicked("status-detail")}
                                            />
                                        </ExecutiveContainerDetailt>
                                    </StyledCardStatusManagementContainer>
                                </Col>
                                <Col sm={6}>
                                    <StyledCardStatusManagementContainer>
                                        <StyledDetailStateContainer>
                                            <StyledSpaceBetween>
                                                <Heading type="H4">Gestión</Heading>
                                                <Heading type="H4">Total: {total_management}</Heading>
                                            </StyledSpaceBetween>
                                        </StyledDetailStateContainer>

                                        {totalDetailManagement && totalDetailManagement.map(casilla => (
                                            <StyledDetailStateContainer key={casilla.title}>
                                                <StyledSpaceBetween>
                                                    <Heading type="H5">{casilla.title}</Heading>
                                                    <Heading type="H5">{casilla.value}</Heading>
                                                </StyledSpaceBetween>
                                                <StyledProgressbarContainer>
                                                    <BackgroundProgressbar />
                                                    <GradientProgressbar percentage={(casilla.value * 100) / total_management} detail='management' />
                                                </StyledProgressbarContainer>
                                            </StyledDetailStateContainer>
                                        ))}

                                        <ExecutiveContainerDetailt>
                                            <ExecutiveCardButton
                                                text="Ver detalle"
                                                size="s"
                                                onClick={() => this.onInnerPageDetailClicked("management-detail")}
                                                disabled={totalDetailManagement.length > 0 ? false : true}
                                            />
                                        </ExecutiveContainerDetailt>
                                    </StyledCardStatusManagementContainer>
                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                )}
                {error && !loading && (
                    <ContainerCenter>
                        <Informative title="Ups! Ha ocurrido un error 😢" />
                    </ContainerCenter>
                )}

            </InnerViewLayout>
        );
    }
}

export default ExecutiveDetail;
