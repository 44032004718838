import React, { Component } from "react";
import { jwtDecode } from "jwt-js-decode";

import { InnerViewLayout, Loader } from "../../components";
import { Col, Row } from "react-grid-system";
import { Heading, PseudoDatePicker } from "hooly-ui-kit";
import {
  StyledSubTitle,
  StyledCardPrimary,
  StyledTitleCardPrimary,
  StyledNumberCardPrimary,
  PaddingCard,
  StyledCardSecond,
  StyledTitleCardSecond,
  StyledNumberCardSecond,
  WrapperHeading,
} from "./styles/GeneralStyles";

// Services
import { getAllTotal } from "./../../services/index";
import { getDepartmentByUserId } from "../../services/departmentService";

const moment = require("moment");

export default class General extends Component {
  constructor(props) {
    super(props);

    this.state = {
      principales: [],
      otros: [],
      loadingStatistics: true,
      first_date: "",
      end_date: "",
    };
  }

  componentDidMount() {
    const currentDay = this.getCurrentDay();
    this.handleGetAllTotalDates(currentDay);
  }

  getDepartmentName = async () => {
    const { token } = JSON.parse(localStorage.getItem("hooly-cognito-session"));
    const jwt = jwtDecode(token);
    const userId = jwt.payload.username;
    const data = await getDepartmentByUserId(userId);
    const department = data.data.data;
    return department;
  };

  async handleGetAllTotalDates(dates) {
    try {
      this.setState({ loadingStatistics: true });
      const first_date = moment(dates[0]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
      const end_date = moment(dates[1]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
      const department = await this.getDepartmentName();

      const total = await getAllTotal(first_date, end_date, department.name, department['_id']);

      this.setState({
        loadingStatistics: false,
        principales: total.principales,
        otros: total.otros,
      });
    } catch (error) {
      console.log(error);
    }
  }

  getCurrentDay() {
    const today = moment();
    const todayBeginningOfDay = today.startOf("days").toDate();
    const todayEndOfDay = today.endOf("days").toDate();
    return [todayBeginningOfDay, todayEndOfDay];
  }

  render() {
    const { principales, otros, loadingStatistics } = this.state;

    const maxDate = moment().toDate();
    const minDate = moment("20190101", "YYYYMMDD").toDate();

    return (
      <InnerViewLayout>
        {loadingStatistics && <Loader />}

        <WrapperHeading>
          <Heading type="H2">General</Heading>
          <PseudoDatePicker
            onDateSelected={(range) => this.handleGetAllTotalDates(range)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </WrapperHeading>

        <React.Fragment>
          <StyledSubTitle>
            <Heading type="H5">Principales</Heading>
          </StyledSubTitle>

          <Row style={PaddingCard}>
            {principales &&
              principales.map((casilla) => (
                <Col sm={4} key={casilla.title}>
                  <StyledCardPrimary>
                    <StyledTitleCardPrimary>
                      {casilla.title}
                    </StyledTitleCardPrimary>
                    <StyledNumberCardPrimary>
                      {casilla.value}
                    </StyledNumberCardPrimary>
                  </StyledCardPrimary>
                </Col>
              ))}
          </Row>

          <StyledSubTitle>
            <Heading type="H5">Otros</Heading>
          </StyledSubTitle>

          <Row style={PaddingCard}>
            {otros.map((casilla) => (
              <Col sm={3} key={casilla.title}>
                <StyledCardSecond>
                  <StyledTitleCardSecond>{casilla.title}</StyledTitleCardSecond>
                  <StyledNumberCardSecond>
                    {casilla.value}
                  </StyledNumberCardSecond>
                </StyledCardSecond>
              </Col>
            ))}
          </Row>
        </React.Fragment>
      </InnerViewLayout>
    );
  }
}
