import { formatter } from "./../utils/formatters/millify";
import { AXIOS_C } from "./cusAxios";

const ENDPOINT =
  process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:3000";
const PATH = "salesforce";

export const getLeadHoolyCountByStatusAndRange = async (
  status,
  start,
  end,
  department = undefined,
  department_id = undefined
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/lead/hooly/count/get_by_status_and_range`,
    {
      params: {
        status,
        start,
        end,
        department,
        department_id
      },
    }
  );
};

export const getLeadHoolyCountByStatusAndRangeAndAlias = async (
  alias,
  start,
  end,
  status
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/lead/hooly/count/get_by_status_and_range_and_alias`,
    {
      params: {
        alias,
        status,
        start,
        end,
      },
    }
  );
};

export const getLeadAllCountByStatusAndRange = async (
  status,
  start,
  end,
  department = undefined,
  department_id = undefined
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/lead/all/count/get_by_status_and_range`,
    {
      params: {
        status,
        start,
        end,
        department,
        department_id
      },
    }
  );
};

export const getOpportunityHoolyCountByStatusAndRange = async (
  stageName,
  start,
  end,
  department = undefined,
  department_id = undefined
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/opportunity/hooly/count/get_by_status_and_range`,
    {
      params: {
        stageName,
        start,
        end,
        department,
        department_id
      },
    }
  );
};

export const getOpportunityHoolyCountByStatusAndRangeAndAlias = async (
  alias,
  start,
  end,
  stageName
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/opportunity/hooly/count/get_by_status_and_range_alias`,
    {
      params: {
        alias,
        stageName,
        start,
        end,
      },
    }
  );
};

export const getSearchManagedStagenameByExecutive = async (
  start,
  end,
  alias,
  selecction,
  limit,
  page
) => {
  return AXIOS_C.get(
    `${ENDPOINT}/${PATH}/collaboration/managed/get_management_info_executive_by_alias`,
    {
      params: {
        start,
        end,
        alias,
        selecction,
        limit,
        page,
      },
    }
  );
};

export const getAllTotalDetails = async (first_date, end_date, username) => {
  const onlyRun = username.substring(0, username.length - 1);
  let management = [];
  let total_management = 0;

  return Promise.all([
    getOpportunityHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "Ganada"
    ),
    getOpportunityHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "Perdida"
    ),
    getLeadHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "Contactado"
    ),
    getLeadHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "No contactado"
    ),
    getLeadHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "Cerrado"
    ),
    getLeadHoolyCountByStatusAndRangeAndAlias(
      onlyRun,
      first_date,
      end_date,
      "Agendado"
    ),
  ])
    .then((responses) => {
      management.push({
        title: "Ganados",
        value: responses[0].data["total"],
      });
      management.push({
        title: "Perdidos",
        value: responses[1].data["total"],
      });
      management.push({
        title: "Contactados",
        value: responses[2].data["total"],
      });
      management.push({
        title: "No Contactados",
        value: responses[3].data["total"],
      });
      management.push({
        title: "Cerrados",
        value: responses[4].data["total"],
      });
      management.push({
        title: "Agendados",
        value: responses[5].data["total"],
      });

      total_management = management.reduce((x, y) => x + parseInt(y.value), 0);

      return { management, total_management };
    })
    .catch((err) => console.log(err));
};

export const getAllTotal = async (first_date, end_date, department, department_id) => {
  let principales = [];
  let otros = [];

  return Promise.all([
    getOpportunityHoolyCountByStatusAndRange(
      "Ganada",
      first_date,
      end_date,
      department,
      department_id
    ),
    getLeadHoolyCountByStatusAndRange(
      "Agendado",
      first_date,
      end_date,
      department,
      department_id
    ),
    getLeadHoolyCountByStatusAndRange(
      "Contactado",
      first_date,
      end_date,
      department,
      department_id
    ),
    getOpportunityHoolyCountByStatusAndRange(
      "Perdida",
      first_date,
      end_date,
      department,
      department_id
    ),
    getLeadHoolyCountByStatusAndRange(
      "No contactado",
      first_date,
      end_date,
      department,
      department_id
    ),
    getLeadAllCountByStatusAndRange(
      "Abierto",
      first_date,
      end_date,
      department,
      department_id
    ),
    getLeadHoolyCountByStatusAndRange(
      "Cerrado",
      first_date,
      end_date,
      department,
      department_id
    ),
  ])
    .then((responses) => {
      principales.push({
        title: "Ganados",
        value: formatter(responses[0].data["total"]),
      });
      principales.push({
        title: "Agendados",
        value: formatter(responses[1].data["total"]),
      });
      principales.push({
        title: "Contactados",
        value: formatter(responses[2].data["total"]),
      });

      otros.push({
        title: "Perdidos",
        value: formatter(responses[3].data["total"]),
      });
      otros.push({
        title: "No Contactados",
        value: formatter(responses[4].data["total"]),
      });
      otros.push({
        title: "Abiertos",
        value: formatter(responses[5].data["total"]),
      });
      otros.push({
        title: "Cerrados",
        value: formatter(responses[6].data["total"]),
      });

      return {
        principales,
        otros,
      };
    })
    .catch((err) => console.log(err));
};
