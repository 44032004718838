import React from 'react';
import { Row } from "react-grid-system";
import { Card } from 'hooly-ui-kit/build/lib';

// styles
import { WrapperUserData, ColData, TextCard } from '../validator.styles';


export default function UserData({ userData }) {
    return (
        <WrapperUserData>
            <Row>
                <ColData sm={6}>
                    <TextCard>
                        <p>Afp de Origen</p>
                    </TextCard>
                    <p>{userData.currentAfp}</p>
                </ColData>
                <ColData sm={6}>
                    <TextCard>
                        <p>Género</p>
                    </TextCard>
                    <p>{userData.gender === 'M' ? 'Masculino' : 'Femenino'}</p>
                </ColData>
                <ColData sm={6}>
                    <TextCard>
                        <p>Nombre</p>
                    </TextCard>
                    <p>{userData.givenNames}</p>
                </ColData>
                <ColData sm={6}>
                    <TextCard>
                        <p>Fecha de nacimiento</p>
                    </TextCard>
                    <p>{userData.birthDate}</p>
                </ColData>
                <ColData sm={6}>
                    <TextCard>
                        <p>Apellido</p>
                    </TextCard>
                    <p>{userData.lastName}</p>
                </ColData>
            </Row>
        </WrapperUserData>
    )
}