import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useForm } from 'react-hook-form';
import * as Yup from "yup";
import { Formik } from "formik";
import jwt_decode from "jwt-decode";

// Utilities
import {
  removeDotsAndHyphen,
  isUserAdmin,
  isUserAudit,
  formatRut,
} from "../../../../utils";

import {
  forgottenPassword, refreshToken
} from "../../../../services";

import {
  addNotification
} from "../../../../redux/actions";

import { logIn } from "../../../../services/authServ";

// Styles
import {
    LoginCard,
    ChangePasswordWrapper,
    Arrow,
    WrapperHeadContent,
    MessageNoRut
} from "../../styles";

// Components
import { Heading, Button, Input } from "hooly-ui-kit";

const SignIn = props => {
  const [signInValues, setSignInValues] = useState({
    accountLoginStep: 1,
    username: "",
    email: ""
  });

  const {
    setError
  } = useForm({ mode: "onChange" });

  const SignInSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "El RUT ingresado no es válido")
      .max(35, "El RUT ingresado no es válido")
      .required("Debes ingresar un RUT"),
    password: Yup.string()
      .min(8, "La contraseña ingresada es muy corta")
      .required("Es necesario que ingreses una contraseña")
  });

  const getErrorMessages = (errorCode) => {
    const errorMessages = [];

    switch (errorCode) {
      default:
        // hacer mensaje por default para errores
        errorMessages.push({field:"username", message: "Ingresar usuario correcto de Hooly"});
        errorMessages.push({field:"password", message: "Ingresar contraseña contraseña de Hooly"});
        break;
    }

    return errorMessages;
  }

  const onSendVericationCode = async () => {
    try {
        const forgottenPasswordResponse = await forgottenPassword(removeDotsAndHyphen(signInValues.username))
        const emailResponse = forgottenPasswordResponse.data.result.CodeDeliveryDetails.Destination || ""
        const path = `login/change-password?username=${removeDotsAndHyphen(signInValues.username)}&email=${emailResponse}`;
        props.history.push(path, { ...signInValues.username, emailResponse });
    } catch (err) {
        const error_msg = err.response.data.message
        if (error_msg === "Attempt limit exceeded, please try after some time.") {
          props.addNotification({
            id: "error",
            text: `Has excedido el límite de intentos de <br> recuperación de contraseña, por favor <br> vuelve a intentarlo dentro de una hora 😦`,
            type: "notOk"
          });
        }
        else if (error_msg === "User does not exist.") {
            setSignInValues({ ...signInValues, accountLoginStep: 3 });
        }
    }
  }

  const noRutChange = props => {
    return (
      <React.Fragment>
        <div>
          <WrapperHeadContent>
              
              <Arrow
                  className="hly-left-arrow"
                  onClick={() => setSignInValues({ ...signInValues, accountLoginStep: 1 })}
              />

              <MessageNoRut>
                  No puedes recuperar la <br/>
                  contraseña, ya que tu RUT no <br/>
                  está registrado en Hooly Backoffice
              </MessageNoRut>

          </WrapperHeadContent>

          <Button
              text="Siguiente"
              size="full"
              variant="primary"
              onClick={() => setSignInValues({ ...signInValues, accountLoginStep: 1 })}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <LoginCard>

            {signInValues.accountLoginStep === 1 && (
              <React.Fragment>
                <Heading type="H3">
                  Accede al administrador <br /> completando los datos
                </Heading>

                <Formik
                  initialValues={{ username: "", password: "" }}
                  isInitialValid={false}
                  validationSchema={SignInSchema}
                  onSubmit={ async (values, { setSubmitting, setFieldError }) => {
                    const { username, password } = values;
                    const formatUsername = removeDotsAndHyphen(username);

                    setSubmitting(true);

                    try {
                      const loginUser = await logIn(formatUsername, password);
                      const jwt_decoded = jwt_decode(loginUser.response.token);
                      // push user after validate if is an active user
                      if (isUserAdmin(jwt_decoded["cognito:groups"]))
                        props.history.push("/dashboard/general");
                      else if (isUserAudit(jwt_decoded["cognito:groups"]))
                        props.history.push("/dashboard/grabaciones");
                      else {
                        setFieldError("username", "Debes ser un administrador");
                        setSubmitting(false);
                      }
                    }
                    catch (error) {

                      if (error.response) {
                        const session = error.response.data?.session;
                        if (session) {
                            localStorage.setItem("hooly-session_pass", JSON.stringify(session));

                            const path = `login/create-password?username=${formatUsername}`;

                            props.history.push(path, { username })
                        }
                        else {
                          const errorMessages = getErrorMessages(error.message)
                          errorMessages.map(errorMessage => {
                            setFieldError(errorMessage.field, errorMessage.message);
                          });
                          setSubmitting(false);
                        }
                      }
                    }

                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    dirty,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue
                    /* and other goodies */
                  }) => (
                      <form onSubmit={handleSubmit}>
                        <Input
                          label="Escribe tu RUT"
                          name="username"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.username}
                          touched={touched.username}
                          value={values.username}
                          formatter={formatRut}
                          onChange={(event) => {
                            setFieldValue("username",formatRut(event.target.value));
                            signInValues.username = formatRut(event.target.value);
                          }
                          }
                        />

                        <Input
                          label="Escribe tu contraseña"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.password}
                          touched={touched.password}
                          value={values.password}
                          type="password"
                        />

                        <Button
                          color="primary"
                          text="Acceder"
                          type="submit"
                          disabled={isSubmitting || !isValid}
                        />
                      </form>
                    )}
                </Formik>

                <ChangePasswordWrapper onClick={() => onSendVericationCode()}>
                  <p>
                    ¿Olvidaste tu contraseña?
                        <span> </span>
                    <b>Recupérala!</b>
                  </p>
                </ChangePasswordWrapper>
              </React.Fragment>
            )}

            {signInValues.accountLoginStep === 3 ? noRutChange(props) : null}


          </LoginCard>

    </React.Fragment>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addNotification
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(SignIn);