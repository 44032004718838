import React from "react";
import styled from "styled-components";
import { rem } from "polished";

// Components
import { Card, Heading, Button } from "hooly-ui-kit";
import { ThreeDotsOptions } from "../../../../components";
import ExecutiveDetail from "../../InterPages/ExecutiveDetail";

// Styles
const ExecutiveCardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${rem("32px")};
  margin: ${rem("16px")} 0;
  position: relative;

  .ThreeDotsOptions {
    position: absolute;
    top: ${rem("16px")};
    right: ${rem("32px")};
  }
`;

const ExecutiveCardThumb = styled.img`
  margin-bottom: ${rem("16px")};
`;

const ExecutiveCardName = styled(Heading)`
  color: #000;
  display: block;
`;

const ExecutiveCardButton = styled(Button)`
  display: block;
  background: #6236ff;
  box-shadow: 0 18px 40px -12px rgba(38, 0, 172, 0.35);
  margin-top: ${rem("40px")};
`;

const getThumbByGender = gender => {
  if (gender === "M" || gender === "m") {
    return require("./../../../../assets/images/avatar-man.svg");
  } else {
    return require("./../../../../assets/images/avatar-girl.svg");
  }
};

const ExecutiveCard = ({
  executive,
  editUser,
  deleteUser,
  onSelectedDetailExecutive
}) => {
  return (
    <ExecutiveCardWrapper>
      <ThreeDotsOptions
        options={[
          { text: "Editar usuario", action: () => editUser(executive) },
          { text: "Eliminar usuario", action: () => deleteUser(executive) }
        ]}
      />
      <ExecutiveCardThumb src={getThumbByGender(executive.gender)} />
      <ExecutiveCardName type="H4">{`${executive.first_name ||
        "None"} ${executive.last_name || "None"}`}</ExecutiveCardName>
      <ExecutiveCardButton
        text="Ver detalle"
        size="m"
        disabled={!executive.enabled}
        size="m"
        onClick={() => onSelectedDetailExecutive(executive)}
      />
    </ExecutiveCardWrapper>
  );
};

export default ExecutiveCard;
