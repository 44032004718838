import React from "react";
import { rem } from "polished";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";

// Libraries
import { Card } from "hooly-ui-kit";
import times from "lodash/times";

// Components
import Skeleton  from "../../../../components/Skeleton/Skeleton";

const ExecutiveCardSkeletonCard = styled(Card)`
  margin: ${rem("16px")} 0;
  padding: ${rem("26px")};
`;

export default function ExecutiveCardSkeleton({ numColumns }) {
  return (
    <ExecutiveCardSkeletonCard>
      <Row>
        {
          times(numColumns, num => <Col key={num}><Skeleton /></Col>)
        }
      </Row>
    </ExecutiveCardSkeletonCard>
  );
}
