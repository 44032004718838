import styled from "styled-components";
import { rem } from "polished";

export const NotificationsHolder = styled.span`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: ${rem("536px")};
  z-index: 20;
`;
