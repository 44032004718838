const INITIAL_STATE = {
  isDeployed: false,
  phoneNumber: "",
  url: null,
  recordingId: ""
};

const audioPlayerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "DISPLAY_AUDIOPLAYER":
      const { phoneNumber, url, recordingId } = action.payload;

      return {
        isDeployed: true,
        url,
        phoneNumber,
        recordingId
      };
    case "CLOSE_AUDIOPLAYER":
      return {
        isDeployed: false,
        url: null,
        phoneNumber: "",
        recordingId: ""
      };

    default:
      return state;
  }
};

export default audioPlayerReducer;
