import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-grid-system";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  ContainerInfoAuditor,
  DialogueGlobe,
  ExecutiveFormField,
  ExecutiveFormFieldLabel,
  ExecutiveTypeProfile,
  InfoAuditorImg,
  LabelCard,
} from "./styles/ExecutiveFormStyles";

// Components
import { Input, Select, ToggleSwitchButton } from "hooly-ui-kit";
import { LoaderButton } from "../../../../components";

// Utils
import { formatRut, validateRut, removeDotsAndHyphen } from "../../../../utils";

//assets
import info_icon from "../../../../assets/images/info_icon.svg";

// Validation Schema
const UserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "El nombre ingresado no es válido")
    .matches(
      new RegExp("^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ffs]*)$"),
      { message: "Debes ingresar un nombre válido" }
    )
    .required("Debes ingresar un nombre"),
  lastName: Yup.string()
    .min(2, "El apellido ingresado no es válido")
    .matches(
      new RegExp("^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ffs]*)$"),
      { message: "Debes ingresar un nombre válido" }
    )
    .required("Debes ingresar un apellido"),
  rut: Yup.string()
    .required("Debes ingresar un RUT")
    .test("Test RUT", "Debes ingresar un RUT válido", (value) =>
      validateRut(value)
    ),
  phone: Yup.string().required("Debes ingresar un número de teléfono"),
  role: Yup.string().min(2, "Debes seleccionar un rol para el usuario").required("Debes seleccionar un rol para el usuario"),
  department: Yup.string().required(
    "Debes seleccionar un departamento para el usuario"
  ),
  gender: Yup.string().max(1).min(1).required("Debes seleccionar el género"),
  email: Yup.string()
    .email("Debes ingresar un correo electrónico válido")
    .required("Es necesario que ingreses un correo electrónico"),
});

// Styles
const ExecutiveFormButton = styled(LoaderButton)`
  width: 100%;
`;

class ExecutiveForm extends Component {

  handleStartProfiles = () => {
    const profileObject = {
      role: "",
      auditor: "",
    };
    const profileArray = this.props.executive.cognito_group;
    if (profileArray) {
      profileArray.forEach((role) => {
        switch (role) {
          case "gp_cognito_hooly_executives":
            profileObject.role = "gp_cognito_hooly_executives";
            break;
          case "gp_cognito_hooly_executives_traditional":
            profileObject.role = "gp_cognito_hooly_executives";
            break;
          case "gp_cognito_hooly_admin":
            profileObject.role = "gp_cognito_hooly_admin";
            break;
          case "gp_cognito_hooly_executives_high_value":
            profileObject.role = "gp_cognito_hooly_executives"
            break;
          default:
            break;
        }
      });
      profileArray.forEach((role) => {
        switch (role) {
          case "gp_cognito_hooly_audit":
            if (profileObject.role === "") {
              profileObject.role = "gp_cognito_hooly_audit";
            } else {
              profileObject.auditor = "gp_cognito_hooly_audit";
            }
            break;
          default:
            break;
        }
      });

      return profileObject;
    }

    return profileObject;
  };

  departmentStart = () => {
    if (!this.props.executive.departmentId) {
      return '';
    }
    const departments = this.props.departments.find((department) => {
      return department.value === this.props.executive.departmentId;
    });
    if (!departments) {
      return '';
    }

    return departments;
  };

  constructor(props) {
    super(props);
    this.state = {
      profiles: this.handleStartProfiles(),
      initialProfiles: this.handleStartProfiles(),
      oldProfiles: this.props.executive.cognito_group || [],
      hover: "",
      selectedDepartment: this.departmentStart(),
      departments: this.props.departments || [{ department: '', value: '' }],
    };
  }

  handleSwitchButton = (e, setValue) => {
    if (e) {
      setValue("auditor", "gp_cognito_hooly_audit");
      this.setState((prevState) => ({
        profiles: {
          ...prevState.profiles,
          auditor: "gp_cognito_hooly_audit",
        },
      }));
    } else {
      setValue("auditor", "");
      this.setState((prevState) => ({
        profiles: {
          ...prevState.profiles,
          auditor: "",
        },
      }));
    }
  };

  departmentSelected = (departmentId, departments, setValue, setFieldTouched) => {
    departments.forEach((element) => {
      if (element.value === departmentId) {
        this.setState({ selectedDepartment: element });
        setValue("department", departmentId);
        this.setState({ profiles: { role: '', auditor: '' } });
        setValue("role", "");
        setValue("auditor", "");
        setFieldTouched('role', true, true);
        setFieldTouched('department', false, true);
        setFieldTouched('rut', false, true);
        setFieldTouched('firstName', false, true);
        setFieldTouched('lastName', false, true);
        setFieldTouched('gender', false, true);
        setFieldTouched('email', false, true);
        setFieldTouched('phone', false, true);
      }
    });
  };
  handleRole = (event, setValue, errors) => {
    setValue("role", event);
    this.setState((prevState) => ({
      profiles: {
        ...prevState.profiles,
        role: event,
      },
    }));
    delete errors.role;
  };

  renderText = () => {
    return (
      <DialogueGlobe>
        Activando el perfil adicional quedará en 2 departamentos,{" "}
        {this.state.selectedDepartment.department} y Administrador
      </DialogueGlobe>
    );
  };

  onMouseEnterHandler = () => {
    return this.setState({ hover: this.renderText() });
  };

  onMouseLeaveHandler = () => {
    return this.setState({ hover: "" });
  };

  render() {
    const departments = this.state.departments;
    const roles = this.state.selectedDepartment.profiles;
    return (
      <Formik

        initialValues={{
          username: this.props.executive.username || "",
          firstName: this.props.executive.first_name || "",
          lastName: this.props.executive.last_name || "",
          rut: formatRut(this.props.executive.username) || "",
          phone: this.props.executive.phone || "",
          role: this.state.initialProfiles.role,
          gender: this.props.executive.gender || "",
          email: this.props.executive.email || "",
          password: "",
          auditor: this.state.profiles.auditor,
          department: this.props.executive.departmentId || "",
        }}
        isInitialValid={false}
        validateOnChange={true}
        validationSchema={UserSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          setSubmitting(true);
          const rol = [];

          if (values.role === 'gp_cognito_hooly_executives') {
            switch (this.state.selectedDepartment.department) {
              case 'CARS':
                if (values.auditor.length > 0) {
                  rol.push(values.auditor);
                }
                rol.push(values.role);
                break;
              case 'Tradicional':
                if (values.auditor.length > 0) {
                  rol.push(values.auditor);
                }
                rol.push('gp_cognito_hooly_executives_traditional');
                break;
              case 'AV':
                if (values.auditor.length > 0) {
                  rol.push(values.auditor);
                }
                rol.push('gp_cognito_hooly_executives_high_value');
                break;
              default:
                break;
            }
          } else {
            rol.push(values.role);
          }
          const userValues = {
            user_name: removeDotsAndHyphen(values.rut),
            name: values.firstName,
            family_name: values.lastName,
            password_temporary: `Hooly123.`,
            role: rol,
            gender: values.gender,
            email: values.email,
            phone: values.phone,
            department: values.department,
            oldRoles: this.state.oldProfiles,
          };
          this.props
            .createOrEditPromiseBasedFunction(userValues)
            .then((succ) => this.props.createOrEditCompleted(succ))
            .catch((err) => this.props.createOrEditCompleted(err))
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid,
          setFieldTouched,
          validateForm,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Input
                  label="Nombre"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.firstName}
                  touched={touched.firstName}
                  value={values.firstName}
                />
              </Col>
              <Col>
                <Input
                  label="Apellido"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.lastName}
                  touched={touched.lastName}
                  value={values.lastName}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {this.props.method === "create" ? (
                  <Input
                    label="RUT"
                    name="rut"
                    onChange={(event) =>
                      setFieldValue("rut", formatRut(event.target.value))
                    }
                    onBlur={handleBlur}
                    error={errors.rut}
                    touched={touched.rut}
                    value={values.rut}
                  />
                ) : (
                  <ExecutiveFormField>
                    <ExecutiveFormFieldLabel>RUT</ExecutiveFormFieldLabel>
                    {values.rut}
                  </ExecutiveFormField>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  label="Teléfono"
                  name="phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.phone}
                  touched={touched.phone}
                  value={values.phone}
                  maxLength="12"
                />
              </Col>
              <Col>
                <Select
                  label="Género"
                  name="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.gender}
                  touched={touched.gender}
                  value={values.gender}
                  options={[
                    { gender: "Masculino", value: "m" },
                    { gender: "Femenino", value: "f" },
                  ]}
                  displayValueAccesor="gender"
                  internalValueAccesor="value"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Select
                  label="Departamento"
                  name="department"
                  onChange={(event) => {
                    this.departmentSelected(
                      event.target.value,
                      departments,
                      setFieldValue,
                      setFieldTouched
                    );
                    validateForm('role');

                  }}
                  onBlur={handleBlur}
                  error={errors.department}
                  touched={touched.department}
                  value={values.department}
                  options={departments}
                  displayValueAccesor="department"
                  internalValueAccesor="value"
                />
              </Col>
              <Col>
                <Select
                  label="Rol"
                  name="role"
                  onChange={(event) => {
                    this.handleRole(
                      event.target.value,
                      setFieldValue,
                      errors
                    );
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  error={errors.role}
                  touched={touched.role}
                  value={values.role}
                  options={roles}
                  displayValueAccesor="role"
                  internalValueAccesor="value"
                />
              </Col>
            </Row>
            {/* implementacion perfiles para ejecutivo */}
            {this.state.profiles.role === "gp_cognito_hooly_executives" &&
              this.state.selectedDepartment.department === "Tradicional" ? (
              <Row>
                <Col>
                  <p>Tipo de perfil</p>
                  <ExecutiveTypeProfile>
                    {" "}
                    Venta Tradicional{" "}
                  </ExecutiveTypeProfile>
                </Col>
                <Col>
                  <LabelCard>
                    <Row>
                      <p>Perfil adicional</p>
                      <InfoAuditorImg
                        onMouseOver={this.onMouseEnterHandler}
                        onMouseLeave={this.onMouseLeaveHandler}
                      />
                      {this.state.hover}
                    </Row>
                    <Row>
                      <ToggleSwitchButton
                        label="Auditor"
                        name="auditor"
                        handleChange={(event) =>
                          this.handleSwitchButton(
                            event.target.checked,
                            setFieldValue
                          )
                        }
                        value={"Venta tradicional"}
                        check={this.state.profiles.auditor.length !== 0}
                      />
                    </Row>
                  </LabelCard>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {this.state.profiles.role === "gp_cognito_hooly_executives" &&
              this.state.selectedDepartment.department === "CARS" ? (
              <Row>
                <Col>
                  <p>Tipo de perfil</p>
                  <ExecutiveTypeProfile>Atracción</ExecutiveTypeProfile>
                </Col>
                <Col>
                  <LabelCard>
                    <Row>
                      <p>Perfil adicional</p>
                      <ContainerInfoAuditor>
                        <InfoAuditorImg
                          src={info_icon}
                          onMouseOver={this.onMouseEnterHandler}
                          onMouseLeave={this.onMouseLeaveHandler}
                        />
                        {this.state.hover}
                      </ContainerInfoAuditor>
                    </Row>
                    <Row>
                      <ToggleSwitchButton
                        label="Auditor"
                        name="auditor"
                        handleChange={(event) => {
                          this.handleSwitchButton(
                            event.target.checked,
                            setFieldValue
                          );
                        }}
                        value={"Venta tradicional"}
                        check={this.state.profiles.auditor.length !== 0}
                      />
                    </Row>
                  </LabelCard>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {this.state.profiles.role === "gp_cognito_hooly_executives" &&
              this.state.selectedDepartment.department === "AV" ? (
              <Row>
                <Col>
                  <p>Tipo de perfil</p>
                  <ExecutiveTypeProfile>Alto valor</ExecutiveTypeProfile>
                </Col>
                <Col>
                  <LabelCard>
                    <Row>
                      <p>Perfil adicional</p>
                      <ContainerInfoAuditor>
                        <InfoAuditorImg
                          src={info_icon}
                          onMouseOver={this.onMouseEnterHandler}
                          onMouseLeave={this.onMouseLeaveHandler}
                        />
                        {this.state.hover}
                      </ContainerInfoAuditor>
                    </Row>
                    <Row>
                      <ToggleSwitchButton
                        label="Auditor"
                        name="auditor"
                        handleChange={(event) => {
                          this.handleSwitchButton(
                            event.target.checked,
                            setFieldValue
                          );
                        }}
                        value={"Alto valor"}
                        check={this.state.profiles.auditor.length !== 0}
                      />
                    </Row>
                  </LabelCard>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row>
              <Col>
                <Input
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                  value={values.email}
                  type="email"
                />
              </Col>
            </Row>
            <ExecutiveFormButton
              color="primary"
              text={this.props.buttonText}
              type="submit"
              size="l"
              disabled={isSubmitting || !isValid}
              loading={isSubmitting ? true : false}
            />
          </form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => state.selectDepartments;

export default connect(mapStateToProps, null)(ExecutiveForm);
