import React, { Component } from "react";
import styled from "styled-components";
import { rem } from "polished";

// Components
import { InnerViewLayout, Loader, PseudoTable } from "../../../../components";
import { Heading } from "hooly-ui-kit";
import RecordingDetailCard from "../../components/ExecutiveDetail/components/RecordingDetailCard";

// Services
import { getRecordingsByLeadId, getSignedUrl } from "../../../../services";

const headerOptions = [
    {
        title: "Fecha y hora",
        function: null
    },
    {
        title: "",
        function: null
    }
];

const WrapperLoading = styled.div`
    text-align: center;
    padding-top: 40%;
`;

export default class ExecutiveLeadRecordings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recordings: [],
            loadingRecordings: true,
            totalPages: 0,
            currentPage: 1,
            currentMenuOption: "Todos",
            isAudioPlaying: false,
            recordingPlaying: null,
            lastRecordingPlayed: null
        };

        // Audio variable for handling audio streams
        this.audio = new Audio();
    };

    componentDidMount() {
        const { lead_id, username } = this.props

        setTimeout(() => {
            getRecordingsByLeadId(lead_id, username)
                .then(succ => {
                    this.setState({
                        recordings: succ.data.recordings,
                        loadingRecordings: false
                    });
                }).catch(err => {
                    this.setState({ loadingRecordings: false }, () => console.log(err))
                });
        }, 500)

        /**
     * AUDIO EVENT LISTENERS
     */
        this.audio.addEventListener("ended", () => {
            this.setState({ recordingPlaying: null });
        });
    };


    /**
     * Play the audio associated with the URL coming from the service
     */
    playAudio = (audioUrl, recordingId) => {
        const { lastRecordingPlayed } = this.state;

        if (lastRecordingPlayed === recordingId) {
            this.setState({ recordingPlaying: recordingId }, () => this.audio.play());
        } else {
            // Sign the AWS S3 URL
            getSignedUrl(audioUrl)
                .then(succ => {
                    // Create a new Audio object with the signed URL from AWS
                    this.audio.setAttribute("src", succ.data);

                    this.setState(
                        {
                            isAudioPlaying: true,
                            lastRecordingPlayed: recordingId,
                            recordingPlaying: recordingId
                        },
                        () => {
                            this.audio.load();
                            this.audio.play();
                        }
                    );
                })
                .catch(err => console.log(err));
        }
    };

    /**
     * Pauses the audio currently playing
     */
    pauseAudio = recordingId => {
        this.setState({ recordingPlaying: null }, () => this.audio.pause());
    };

    /**
     * Downloads the audio file
     * Opens a new window and after 1s, the window closes
     */
    downloadFile = url => {
        getSignedUrl(url)
            .then(succ => {
                const windowForDownload = window.open(succ.data);
                setTimeout(() => {
                    windowForDownload.close();
                }, 1000);
            })
            .catch(err => console.log(err));
    };

    render() {
        const { phone_number } = this.props;
        const {
            recordings,
            loadingRecordings,
            recordingPlaying
        } = this.state;
        return (
            <InnerViewLayout>
                <InnerViewLayout.SearchbarAndOptions>
                    <Heading type="H4">{phone_number}</Heading>
                </InnerViewLayout.SearchbarAndOptions>
                {loadingRecordings && (<WrapperLoading><Loader /></WrapperLoading>) || (
                    <React.Fragment>
                        {recordings.length > 0 && (
                            <PseudoTable headers={headerOptions}>
                                {recordings.map(recording => (
                                    <RecordingDetailCard
                                        recording={recording}
                                        key={recording.id}
                                        onAudioPlay={audioUrl =>
                                            this.playAudio(audioUrl, recording.recording_sid)
                                        }
                                        onAudioPaused={() => this.pauseAudio(recording.recording_sid)}
                                        onDownloadClicked={url => this.downloadFile(url)}
                                        isThisPlaying={recordingPlaying === recording.recording_sid}
                                    />
                                ))}
                            </PseudoTable>
                        )}
                    </React.Fragment>
                )}
                {!loadingRecordings && recordings.length == 0 && (
                    <Heading type="H4">
                        Ups! no hay resultados para tu búsqueda
                        <span role="img" aria-label="Triste">
                            😢
                        </span>
                    </Heading>
                )}
            </InnerViewLayout>
        )

    }

};
