import React from "react";

// Styles
import {
  PaginatorWrapper,
  PaginatorButton,
  PaginatorContent
} from "./styles/PaginatorStyles";

export default function Paginator({
  totalPages,
  pageClicked,
  currentPage,
  beforePressed,
  followingPressed
}) {
  return (
    <PaginatorWrapper>
      <PaginatorButton
        active={currentPage !== 1}
        disabled={currentPage === 1}
        onClick={() => beforePressed(currentPage - 1)}
      >
        <i className="hly-chevron-left" />
      </PaginatorButton>
      <PaginatorContent>{`${currentPage} de ${totalPages}`}</PaginatorContent>
      <PaginatorButton
        active={totalPages !== currentPage}
        disabled={totalPages === currentPage}
        onClick={() => followingPressed(currentPage + 1)}
      >
        <i className="hly-chevron-right" />
      </PaginatorButton>
    </PaginatorWrapper>
  );
}
