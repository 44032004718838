import React, { useState, useEffect } from 'react';
import { Heading, Informative } from 'hooly-ui-kit/build/lib';
import { merge, Subject, of, BehaviorSubject } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    map,
    switchMap,
    filter,
    catchError,
    tap
} from 'rxjs/operators';
import { Formik } from "formik";
// Utils
import { formatRut, formatRutWithoutDots, removeDotsAndHyphen } from '../../utils';
import { Button, Input } from "hooly-ui-kit";
// Components
import { InnerViewLayout, Loader } from '../../components';
import UserData from './components/UserData';
// Styles
import {
    WrapperValidator,
    WrapperMenu,
    WrapperSearchMenu,
    FitLoader,
    WrapperLoader,
    WrapperResults,
    SearchBarValidator
} from './validator.styles';
// Services
import { getUserDataWithRut } from '../../services';
// asserts
import rayo from "./../../assets/images/Rayo.svg";
export default function Validator() {
    const [state, setState] = useState({
        data: null,
        loading: false,
        errorMessage: '',
        noResults: false,
        initialSearch: true,
    })

    const [search, setSearch] = useState("");
    const [subject, setSubject] = useState(null);
    useEffect(() => {
        if (subject === null) {
            const sub = new BehaviorSubject('');
            setSubject(sub);
        } else {
            const observable = subject.pipe(
                map(s => s.trim()),
                distinctUntilChanged(),
                filter(s => s.length >=10),
                debounceTime(200),
                switchMap(rut =>
                    merge(
                        of({ errorMessage: '', noResults: true, loading: true }),
                        getUserDataWithRut(formatRutWithoutDots(rut)).then(res => {
                            setState({
                                data: res.data.userPersonalData,
                                loading: false,
                                noResults: !!res.data === false,
                                initialSearch: false
                            });
                        }).catch(err => {
                            setState({
                                data: null,
                                loading: false,
                                errorMessage: err,
                                initialSearch: false,
                                noResults: true
                            })
                        })
                    )
                ),
                catchError(e => ({
                    loading: false,
                    errorMessage: 'An application error occured'
                }))
            ).subscribe(newState => {
                setState(s => Object.assign({}, s, newState));
            });
            return () => {
                observable.unsubscribe();
                subject.unsubscribe();
            }
        }
    }, [subject]);

    return (
        <InnerViewLayout>
            <InnerViewLayout.SearchbarAndOptions>
                <Heading type="H2">Validador de AFP</Heading>
            </InnerViewLayout.SearchbarAndOptions>
            <WrapperValidator>
                <WrapperMenu>
                    <div style={{ "margin": "32px" }}>
                        <Heading type="H5" >
                            Valida la Afp de origen y la información <br />
                        de los clientes ingresando su rut
                    </Heading>
                    </div>
                    <WrapperSearchMenu>
                        <SearchBarValidator
                            className="Searchbar"
                            placeholder="Ingresa un rut para buscar"
                            value={search}  
                            maxLength={12}
                            onChange={async (e) => {
                                setSearch(formatRut(e));

                                if (subject) {
                                    return subject.next(e);      
                                }
                            }}
                        />
                    </WrapperSearchMenu>
                </WrapperMenu>
                <WrapperResults>
                    {(!state.loading && !state.noResults && state.data) && (
                        <UserData userData={state.data} />
                    )}
                    <WrapperLoader>
                        {state.loading && <Loader />}
                    </WrapperLoader>
                    {(!state.loading && state.noResults && !state.initialSearch) && (
                        <Informative
                            image={rayo}
                            title="Ups! no hay resultados para tu búsqueda 😢"
                        />
                    )}
                </WrapperResults>
            </WrapperValidator>
        </InnerViewLayout >
    );
}