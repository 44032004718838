const INITIAL_STATE = {
  isDeployed: false,
  componentToRender: null
};

const backdropReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_BACKDROP":
      const { componentToRender } = action.payload;

      return {
        isDeployed: true,
        componentToRender
      };
    case "CLOSE_BACKDROP":
      return {
        isDeployed: false,
        componentToRender: null
      };
    case "CHANGE_BACKDROP_COMPONENT_TO_RENDER":
      return { componentToRender: action.payload.componentToRender };
    default:
      return state;
  }
};

export default backdropReducer;
