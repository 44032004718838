import { AXIOS_C } from "./cusAxios";

const ENDPOINT =
  process.env.REACT_APP_HOOLY_BACKOFFICE_API_URL || "http://localhost:3000";

export const getAllDepartments = async () => {
  try {
    return AXIOS_C.get(`${ENDPOINT}/department/all`);
  } catch (error) {
    console.log(error);
  }
};

export const getDepartmentByUserId = async (id) => {
  try {
    return AXIOS_C.get(`${ENDPOINT}/department/by_user/${id}`);
  } catch (error) {
    console.log(error);
  }
};
