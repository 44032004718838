import React from "react";
import styled, { css } from "styled-components";
import { rem } from "polished";
import { Button } from "hooly-ui-kit";

const ButtomWrapper =  styled.div`
  display: flex;
  margin-top: 150px;

  &:hover {
    
    button {
      background: rgb(255, 255, 255);
      color: rgb(98, 54, 255);
    }

    i {
      color: rgb(98, 54, 255);
    }
  }
`;

const ButtonExit = styled(Button)`
  display: flex;
  align-items: center;
  background: rgb(98, 54, 255);
  border-radius: 25.5px;
  height: 45px;
  padding-right: 60px;

  color: rgb(255, 255, 255);
`;

const Exit = styled.i`
  position: absolute;
  margin-left: 155px;
  margin-top: 25px;
  font-size: 1.5rem;

  color: rgb(255, 255, 255);
`;


function AsExitButton() {
  return class extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        rightIcon: "hly-exit-to-app"
      };
    }

    render() {
      const { rightIcon } = this.state;

      return (
        <ButtomWrapper>
            <ButtonExit
              {...this.props}
              rightIcon={rightIcon}
            />
            <Exit className={rightIcon} />
        </ButtomWrapper>
      );
    }
  };
}

export default AsExitButton;
