import millify from "millify";

const units = ['', 'K', 'M', 'B', 'T', 'P', 'E'];

export const formatter = (number) => {
    try {
        return millify((number), { units, space: false, lowerCase: true });    
    } catch (error) {
        return 0;   
    }
}