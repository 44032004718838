import styled from "styled-components";
import { rem } from "polished";

// Components
import { Card } from "hooly-ui-kit";

// Styles
import { theme } from '../../theme';

// Assets
import hoolyBg from "../../assets/images/hooly-v2-bg.svg";

export const LoginWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #4706c8;
  padding: ${rem("80px")};
  box-sizing: border-box;
  background-image: url(${hoolyBg});
  background-repeat: no-repeat;
  background-size: cover;
`;

const LoginPane = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoginPaneLeft = styled(LoginPane)`
  width: 40%;
  flex-direction: column;
  color: white;
  text-align: center;
  line-height: ${rem("32px")};
  font-size: ${rem("18px")};
  font-weight: 600;
`;

export const LoginPaneRight = styled(LoginPane)`
  width: 60%;

  h3 {
    text-align: center;
    margin-bottom: ${rem("32px")};
    color: #141414;
    font-weight: 900;
  }
`;

export const LoginCard = styled(Card)`
  padding: ${rem("32px")};

  button {
    width: 100%;
  }
`

export const RutHolder = styled.span`
	display: block;
	background-color: #5e6a73;
	border-radius: ${rem('16px')};
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${theme.white};
	font-size: ${rem('18px')};
	font-weight: 700;
	padding: ${rem('6px')} ${'16px'};
	margin: ${rem('24px')} ${'0px'};
`;

export const Arrow = styled.i`
	padding-right: ${rem('8px')};
	transform: translateY(100%);
	color: ${theme.purple};
	cursor: pointer;
`;

export const ChangePasswordWrapper = styled.div`
	cursor:pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		color: ${theme.primary};
  }
`;

export const WrapperHeadContent = styled.div`
	display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

// hard styles
export const CodeSentText = {
  lineHeight: "50px", 
  fontSize: "28px", 
  padding: "0 20px"
};

export const MessageNoRut = styled.h4`{
  line-height: 50px; 
  font-size: 28px!important; 
  color: #E74C3C;
  padding: 0 20px;
  font-family: Nunito;
  font-weight:800;
  margin: 0;
`;