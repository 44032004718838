import React, { Component } from "react";
import styled from "styled-components";
import { rem } from "polished";
import { Row, Col } from "react-grid-system";
import moment from "moment";
import { isEqual } from "lodash";

// Components
import { Card, Heading } from "hooly-ui-kit";
import ReactStopwatch from "react-stopwatch";

// Assets
import girlAvatar from "../../../../assets/images/avatar-girl.svg";
import manAvatar from "../../../../assets/images/avatar-man.svg";

// Styles
const LiveCardImage = styled.img`
  margin-top: ${rem("35px")};
  margin-bottom: ${rem("30px")};
`;

const LiveCardStatusCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${rem("32px")};
  margin-bottom: ${rem("32px")};
`;

const LiveCardStatus = styled.div`
  background-color: ${p =>
    p.status === "Ready" ? "rgba(30, 191, 112, 0.2)" : "rgba(247,183,49,0.20)"};
  border-radius: ${rem("8px")};
  margin: ${rem("18px")} 0;
  padding: ${rem("4px")} ${rem("16px")};
  font-weight: 800;
`;

const LiveCardStatusText = styled.span`
  color: ${p => (p.status === "Ready" ? "#1ebf70" : "#fb9c00")};
`;

const LiveCardTimer = styled.span`
  color: #141414;
  font-family: "Marmelat";
  font-size: ${rem("20px")};
  font-weight: 800;

  i {
    margin-rigth: auto;
  }

  margin-bottom: ${rem("25px")};
`;

/**
 * It calculates the difference between two moments. The info retrieved is usefull for the stopwatch
 * @param {Date} lastUpdateDateTime The DateTime representing the last time the status was updated
 * @param {*} measure The measure of time to retrieve the difference
 */
function differenteceBetweenLastUpdateAndPesentTime(
  lastUpdateDateTime,
  measure = "seconds"
) {
  const lastUpdate = moment.utc(lastUpdateDateTime);
  const currentTime = moment.utc();

  const differenceBetweenTimes = currentTime.diff(lastUpdate);

  const duration = moment.duration(differenceBetweenTimes);

  return measure != "hours" ? duration.get(measure) : Math.floor(duration.asHours());
}

class LiveCard extends Component {
  shouldComponentUpdate(nextProps, _) {
    if (isEqual(this.props, nextProps)) return false;

    return true;
  }

  render() {
    const {
      first_name: firstName,
      last_name: lastName,
      status,
      last_update_date_utc,
      gender
    } = this.props.executiveStatus;

    return (
      <LiveCardStatusCard>
        <LiveCardImage
          src={gender === "M" ? manAvatar : girlAvatar}
          alt="Hooly Avatar"
        />
        <Heading type="H5">{`${firstName} ${lastName}`}</Heading>
        <LiveCardStatus status={status}>
          <LiveCardStatusText status={status}>{status}</LiveCardStatusText>
        </LiveCardStatus>
        <ReactStopwatch
          seconds={differenteceBetweenLastUpdateAndPesentTime(
            last_update_date_utc,
            "seconds"
          )}
          minutes={differenteceBetweenLastUpdateAndPesentTime(
            last_update_date_utc,
            "minutes"
          )}x
          hours={differenteceBetweenLastUpdateAndPesentTime(
            last_update_date_utc,
            "hours"
          )}
          render={({ formatted }) => (
            <LiveCardTimer>
              <Row>
                  <Col sm={2}>
                    <i className="hly-clock"></i>
                  </Col>
                  <Col sm={8}>
                    {formatted}
                  </Col>
              </Row>
            </LiveCardTimer>
          )}
        />
      </LiveCardStatusCard>
    );
  }
}

export default LiveCard;
