import axios from 'axios';

import { Subject } from "rxjs";

const endpoint = process.env.REACT_APP_HOOLY_API_URL || 'http://localhost:5005';
const path = 'notifications'

const subject = new Subject();

export const NotificationsService = {
  registerNotification: notification => subject.next(notification),
  getNotifications: () => subject.asObservable()
};