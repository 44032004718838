import styled from "styled-components";
import { rem } from "polished";

export const PseudoTableWrapper = styled.div``;

export const PseudoTableHeader = styled.span`
  font-weight: 800;
  font-size: ${rem("18px")};
`;

export const PseudoTableContent = styled.div`
  height: 50%;
`;

export const PseudoTableRowStyles = {
  padding: `${rem("16px")} ${rem("24px")}`
};
