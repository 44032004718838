import React, { Component } from "react";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import times from "lodash/times";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jwtDecode } from "jwt-js-decode";

// Libraries
import { SearchBar, Heading, Informative, AudioPlayer } from "hooly-ui-kit";
import { PseudoTable } from "../../components";
// Assets
import rayo from './../../assets/images/Rayo.svg';

// Services
import {
  getAllRecordings,
  queryRecordingsWithParams,
  getSignedUrl
} from "../../services";
import { getDepartmentByUserId } from "../../services/departmentService";
// Actions
import { displayAudioPlayer, closeAudioPlayer } from "../../redux/actions";

// Components
import RecordingCardSkeleton from "./components/RecordingCardSkeleton";
import RecordingCard from "./components/RecordingCard";
import { Paginator, InnerViewLayout } from "../../components";

// Styles
import {
  RecordingsContainer,
  RecordingsPaginatorContainer,
  RecordingsContainerCenter,
  WrapperAudioPlayer
} from "./styles/RecordingsStyles";


const menuOptions = [
  {
    value: "Todos"
  },
  {
    value: "Rut"
  },
  {
    value: "Telefono"
  }
];

const headerOptions = [
  {
    title: "Ejecutivo",
    function: null
  },
  {
    title: "Nº Cliente",
    function: null
  },
  {
    title: "Fecha",
    function: null
  },
  {
    title: "Duración",
    function: null
  },
  {
    title: "",
    function: null
  },

];

const itemsPerPage = 6;

class Recordings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recordings: [],
      loadingRecordings: true,
      totalPages: 0,
      currentPage: 1,
      currentMenuOption: "Todos",
      isAudioPlaying: false,
      recordingPlaying: null,
      lastRecordingPlayed: null,
    };

    /**
     * SUBJECTS FOR HANDLING PARAMETERS
     */
    this.input$ = new BehaviorSubject("");
    this.searchBar$ = new BehaviorSubject("Todos");
    this.paginator$ = new BehaviorSubject(1);

    // Audio variable for handling audio streams
    this.audio = new Audio();
  }
  /** Se establece nueva llamada al departamento del usuario, ya que no se logro gestionar de forma correcta
   *  los ciclos de vidas con redux para un usuario auditor */
  getDepartmentName = async () => {
    const { token } = JSON.parse(localStorage.getItem("hooly-cognito-session"));
    const jwt = jwtDecode(token);
    const userId = jwt.payload.username;
    const data = await getDepartmentByUserId(userId);
    const department = data.data.data;
    return department;
  };
  /**
   * LIFECYCLE HOOKS
   */
  async componentDidMount() {
    /**
     * CALCULATE RECORDING'S FILTER OUTCOME THROUGH SUBJECTS
     */
    const department = await this.getDepartmentName();
    this.queryParamsCombination$ = combineLatest(
      this.input$,
      this.searchBar$,
      this.paginator$
    )
      .pipe(
        tap(([inputValues, searchbarValues, paginator]) =>
          this.setState({ recordings: [], loadingRecordings: true })
        ),
        debounceTime(500)
      )
      .subscribe(([inputValues, searchbarValues, paginator]) => {
        if (inputValues && searchbarValues) {
          queryRecordingsWithParams(
            inputValues,
            searchbarValues.toUpperCase(),
            paginator,
            department.name,
            department['_id']
          ).then(succ => {
            const { recordings, total_pages } = succ.data;

            this.setState({
              recordings: recordings,
              totalPages: total_pages,
              loadingRecordings: false
            });
          });
        } else {
          getAllRecordings(department['_id'], department.name).then(succ => {
            this.setState({
              recordings: succ.data.recordings,
              currentPage: 1,
              loadingRecordings: false,
              totalPages: 0
            });
          });
        }
      });

    /**
     * AUDIO EVENT LISTENERS
     */
    this.audio.addEventListener("ended", () => {
      this.setState({ recordingPlaying: null });
    });
  }

  async componentWillUnmount() {
    await this.props.closeAudioPlayer();

    /**
     * KILL ALL ASYNC AND RXJS OPERATIONS
     */
    this.queryParamsCombination$.unsubscribe();
    this.input$.unsubscribe();
    this.searchBar$.unsubscribe();
    this.paginator$.unsubscribe();
  }

  /**
   * CUSTOM FUNCTIONS
   */

  /**
   * When the menu item is selected, change the currentMenuOption state property to
   * re-render the menu
   */
  onMenuItemSelected = value => {
    this.setState({ currentMenuOption: value });
  };

  /**
   * Play the audio associated with the URL coming from the service
   */
  playAudio = (audioUrl, recordingId, phoneNumber) => {
    const { lastRecordingPlayed } = this.state;

    if (this.props.isDeployed) {
      this.props.closeAudioPlayer();
    }

    if (lastRecordingPlayed === recordingId && !this.props.audioPlayer.isDeployed) {
      // this.setState({ recordingPlaying: recordingId }, () => this.audio.play());
    } else {
      // Sign the AWS S3 URL
      getSignedUrl(audioUrl)
        .then(succ => {
          const url = succ.data;
          this.props.displayAudioPlayer(phoneNumber, url, recordingId);
        })
        .catch(err => console.log(err));
    }
  };

  /**
   * Pauses the audio currently playing
   */
  pauseAudio = recordingId => {
    this.setState({ recordingPlaying: null }, () => this.audio.pause());
  };

  /**
   * Downloads the audio file
   * Opens a new window and after 1s, the window closes
   */
  downloadFile = url => {
    getSignedUrl(url)
      .then(succ => {
        const windowForDownload = window.open(succ.data);
        setTimeout(() => {
          windowForDownload.close();
        }, 1000);
      })
      .catch(err => console.log(err));
  };

  /**
   * When a paginator page is clicked, change the currentPage property of the state in order
   * to modify the Subject value and re-render the paginator with the new page
   */
  onPageClicked = pageNumber => {
    this.setState({ currentPage: pageNumber }, () =>
      this.paginator$.next(pageNumber)
    );
  };

  render() {
    const {
      recordings,
      currentPage,
      currentMenuOption,
      totalPages,
      loadingRecordings,
      recordingPlaying,
    } = this.state;

    return (
      <InnerViewLayout>
        <RecordingsContainer>
          {!this.props.isDeployed ? (
            <InnerViewLayout.Searchbar>
              <Heading type="H2">Grabaciones</Heading>
              <SearchBar
                placeholder="Buscar por RUT ejecutivo o Nº de cliente"
                menuOptions={menuOptions}
                selectedOption={currentMenuOption}
                onChange={value => {
                  if (value) {
                    this.input$.next(value);
                  } else {
                    this.input$.next(value);
                    this.paginator$.next(1);
                  }
                }}
                onMenuItemSelected={itemSelected =>
                  this.setState({ currentMenuOption: itemSelected }, () =>
                    this.searchBar$.next(itemSelected)
                  )
                }
              />
            </InnerViewLayout.Searchbar>
          ) : <div />}
          <PseudoTable headers={headerOptions}>
            {recordings &&
              recordings.map(recording => (
                <RecordingCard
                  key={recording.id}
                  recording={recording}
                  onAudioPlay={audioUrl =>
                    this.playAudio(audioUrl, recording.id, recording.phone_number)
                  }
                  onAudioPaused={() => this.pauseAudio(recording.recording_sid)}
                  onDownloadClicked={url => this.downloadFile(url)}
                  isThisPlaying={recordingPlaying === recording.recording_sid}
                />
              ))}
            {loadingRecordings &&
              times(itemsPerPage, num => <RecordingCardSkeleton key={num} />)}
            {recordings.length === 0 && !loadingRecordings && (
              <RecordingsContainerCenter>
                <Informative image={rayo} title="Ups! no hay resultados para tu búsqueda 😢" />
              </RecordingsContainerCenter>
            )}
          </PseudoTable>
        </RecordingsContainer>
        {totalPages > 1 && (
          <RecordingsPaginatorContainer>
            <p>{`${currentPage * itemsPerPage -
              (itemsPerPage - 1)} a ${currentPage *
              itemsPerPage} de ${totalPages * itemsPerPage} grabaciones`}</p>
            <Paginator
              totalPages={totalPages}
              currentPage={this.state.currentPage}
              beforePressed={newPage => this.onPageClicked(newPage)}
              followingPressed={newPage => this.onPageClicked(newPage)}
            />
          </RecordingsPaginatorContainer>
        )}
      </InnerViewLayout>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ displayAudioPlayer, closeAudioPlayer }, dispatch);

const mapStateToProps = state => { 
  return {
    audioPlayer: state.audioPlayer,
    department: state.department
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Recordings);
