import React from "react";
import styled from "styled-components";
import { rem } from "polished";

// Components
import { Button } from "hooly-ui-kit";

// Styles
const SquareButtonWrapper = styled(Button)`
  border-radius: ${rem("8px")};
  background: #6236ff;
  border: transparent;
  box-shadow: none;
  height: 100%;
  margin: 0;
  max-width: ${rem("180px")};
  padding: ${rem("12px")} ${rem("16px")};
  font-size: ${rem("16px")};
`;

const SquareButton = ({ ...props }) => {
  return <SquareButtonWrapper {...props} />;
};

export default SquareButton;
