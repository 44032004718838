import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { rem } from "polished";

// Components
import { Heading } from "hooly-ui-kit";

// Styles
const LateralMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 5;
  background: #ffffff;
  box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.1);
  width: ${rem("632px")};
  padding: ${rem("40px")} ${rem("32px")};
`;

const LateralMenuMotion = motion.custom(LateralMenuWrapper);

const LateralMenuHeader = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: #6236ff;
  }
`;

const LateralMenuClose = styled.i`
  font-size: ${rem("40px")};
  color: #6236ff;
  cursor: pointer;
`;

const LateralMenuContent = styled.section``;

const LateralMenu = ({
  isDeployed,
  title,
  contentToRender,
  onClosePressed
}) => {
  return (
    <AnimatePresence>
      {isDeployed && (
        <LateralMenuMotion
          key="LateralMenu"
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.2 }}
        >
          <LateralMenuHeader>
            <Heading type="H2">{title}</Heading>
            <LateralMenuClose
              onClick={() => onClosePressed()}
              className="hly-circle-cross"
            />
          </LateralMenuHeader>
          <LateralMenuContent>
            {contentToRender && contentToRender}
          </LateralMenuContent>
        </LateralMenuMotion>
      )}
    </AnimatePresence>
  );
};

export default LateralMenu;
