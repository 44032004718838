import React, { useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

// Components
import ExecutiveForm from '../ExecutiveForm/ExecutiveForm';

// Styles
const CreateEditExecutiveText = styled.p`
	font-size: ${rem('24px')};
	line-height: ${rem('24px')};
	text-align: center;
`;

const CreatedExecutiveOKContainer = styled.div`
  background: rgb(232, 225, 255);
  border-radius: ${rem('16px')};
  margin-top: ${rem('272px')}

  p {
    padding: ${rem('30px')}
    font-size: ${rem('28px')}
    font-weight: normal;
    text-align: center;
  }
`;

const CreateEditExecutive = ({
	method,
	text,
	buttonText,
	createOrEditPromiseBasedFunction,
	createOrEditCompleted,
	executive,
}) => {
	const [createdUser, setCreatedUser] = useState({
		created: false,
		subTitle: text,
		email: '',
	});

	const changeContentCreatedUser = (request) => {
		if (!createdUser) return request;
		else {
			if(request.data?.user)
			{
				setCreatedUser({
					created: true,
					subTitle: '',
					email: request.data.user.email,
				});
			}

			createOrEditCompleted(request);
		}
	};

	return (
		<React.Fragment>
			<CreateEditExecutiveText>{createdUser.subTitle}</CreateEditExecutiveText>

			{(!createdUser.created && (
				<ExecutiveForm
					method={method}
					buttonText={buttonText}
					createOrEditPromiseBasedFunction={createOrEditPromiseBasedFunction}
					executive={executive}
					createOrEditCompleted={(data) => changeContentCreatedUser(data)}
				/>
			)) || (
				<CreatedExecutiveOKContainer>
					<p>
						Se enviará un email a <strong>{createdUser.email}</strong> con la contraseña
						temporal y las instrucciones para crear su cuenta en Hooly.
					</p>
				</CreatedExecutiveOKContainer>
			)}
		</React.Fragment>
	);
};

export default CreateEditExecutive;
