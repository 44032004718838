import React, { Component } from "react";
import { Route } from "react-router-dom";
import { jwtDecode } from "jwt-js-decode";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Styles
import {
  DashboardWrapper,
  DashboardLeftPane,
  DashboardRightPane,
  DashboardLogo,
  WrapperPanelList,
} from "./styles/DashboardStyles";

// Utilies
import { getJwtToken, isUserAdmin, isUserAudit } from "../../utils";

// Internal Views
import {
  Recordings,
  General,
  Live,
  Executives,
  Validator,
} from "../../containers";

// Internal Pages Executives
import ExecutiveDetail from "../Executives/InterPages/ExecutiveDetail";
import ExecutiveTimeDetail from "../Executives/InterPages/ExecutiveConnectDetail/ExecutiveTimeDetail";
import ExecutiveStatusDetail from "../Executives/InterPages/ExecutiveStatusDetail/ExecutiveStatusDetail";
import ExecutiveManagementDetail from "../Executives/InterPages/ExecutiveManagementDetail/ExecutiveManagementDetail";

// Components
import DashboardMenu from "./components/DashboardMenu/DashboardMenu";
import { AudioPlayerComponent } from "../../components";

// Assets
import hoolyLogo from "../../assets/images/hooly-logo.svg";
import formOne from "../../assets/images/form-one.svg";
import formTwo from "../../assets/images/form-two.svg";

// HOC
import AsExitButton from "../../utils/HOC/exitButton";

// Services
import { logOut } from "./../../services/index";
import { getDepartmentByUserId, getAllDepartments} from "../../services/index";
import {
  DashBoardInformationDepartmentHover,
  DashboardInformationMessage,
  DashboardTagDepartment,
  DashboardTagText,
  DashboardTextInformationMessage,
  DashboardTriangle,
} from "./components/DashboardMenu/styles/DashboardTagDepartment";
import { saveDepartment, departmentPicker } from "../../redux/actions";

import { adminMenu, auditMenu } from "../../utils/formatters/menuItems";

const ExitButton = AsExitButton();
class Dashboard extends Component {
  /**
   * UTILITARIAN FUNCTIONS
   */
  constructor(props) {
    super(props);

    this.state = {
      department: {
        department_id: "",
        department_name: "",
      },
      menuItems: adminMenu,
    };
  }

  menuItemClicked = (path) => {
    this.props.history.push(path);
  };

  componentDidMount() {
    if (localStorage.getItem("hooly-cognito-session")) {
      const { token } = JSON.parse(
        localStorage.getItem("hooly-cognito-session")
      );
      const jwt = jwtDecode(token);
      const auditUser = isUserAudit(jwt.payload["cognito:groups"]);
      const userId = jwt.payload.username;

      if (auditUser) {
        this.state.menuItems = auditMenu;
      }

      this.getDepartmentByUserId(userId);
    }
  }

  getDepartmentByUserId = async (userId) => {
    try {
      const data = await getDepartmentByUserId(userId);
      const { _id: department_id, name: department_name } = data.data.data;
      const department = { department_id, department_name };
      this.setState({ department });
      this.props.saveDepartment(department);
      const departments = await getAllDepartments();
      const mappedDepartments = departments.data.data.map((dept) => {
        const profile = dept.profiles.map((role) => {
          switch (role) {
            case "gp_cognito_hooly_admin":
              return { value: role, role: 'Administrador' };
            case "gp_cognito_hooly_executives":
              return { value: role, role: 'Ejecutivo' };
            case "gp_cognito_hooly_audit":
              return { value: role, role: 'Auditor' };
            default:
              return '';
          }
        });
        return { value: dept["_id"], department: dept.name, profiles: profile };
      });

      if (department.department_name === 'Administrador') {
        this.props.departmentPicker(mappedDepartments);
      } else {
        const filterDepartment = mappedDepartments.filter( dep => {
          return dep.department === department.department_name;
        })
        this.props.departmentPicker(filterDepartment);
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderRoutes = () => {
    return this.state.menuItems.map((item) => {
      return (
        <Route
          path={item.menuItemPath}
          component={item.component}
          key={item.menuItemName}
        />
      );
    });
  };

  onLogout = () => {
    logOut()
      .then(() => {
        this.props.history.push("/login");
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { phoneNumber, isDeployed, url, recordingId } = this.props;
    const { department_name } = this.state.department;
    return (
      <DashboardWrapper>
        <DashboardLeftPane>
          <DashboardLogo src={hoolyLogo} />
          <DashBoardInformationDepartmentHover>
                <DashboardInformationMessage>
                  <DashboardTextInformationMessage>
                    Con este perfil verás solo información del departamento{" "}
                    {department_name}
                  </DashboardTextInformationMessage>
                </DashboardInformationMessage>
                <DashboardTriangle />
                <DashboardTagDepartment>
                  <DashboardTagText>
                    Departamento {department_name}
                  </DashboardTagText>
                </DashboardTagDepartment>
          </DashBoardInformationDepartmentHover>
          <DashboardMenu
            location={this.props.location.pathname}
            menuItems={this.state.menuItems}
            menuItemClicked={(path) => this.menuItemClicked(path)}
          />
          <img src={formOne} className="formOne" alt="" />
          <img src={formTwo} className="formTwo" alt="" />

          <ExitButton
            onClick={() => this.onLogout()}
            text={"Cerrar Sesión"}
            size={"s"}
          />
        </DashboardLeftPane>
        <DashboardRightPane>
          <AudioPlayerComponent
            url={url}
            phoneNumber={phoneNumber}
            isDeployed={isDeployed}
            recordingId={recordingId}
          />

          <WrapperPanelList>
            <Route path={"/dashboard/general"} component={General} />
            <Route path={"/dashboard/live"} component={Live} />
            <Route path={"/dashboard/grabaciones"} component={Recordings} />
            <Route path={"/dashboard/validador"} component={Validator} />
            <Route
              exact
              path={"/dashboard/ejecutivos/:page"}
              component={Executives}
            />
            <Route
              exact
              path={"/dashboard/ejecutivos/detail/:username"}
              component={ExecutiveDetail}
            />
            <Route
              exact
              path={"/dashboard/ejecutivos/detail/:username/time-detail"}
              component={ExecutiveTimeDetail}
            />
            <Route
              exact
              path={"/dashboard/ejecutivos/detail/:username/status-detail"}
              component={ExecutiveStatusDetail}
            />
            <Route
              exact
              path={"/dashboard/ejecutivos/detail/:username/management-detail"}
              component={ExecutiveManagementDetail}
            />
          </WrapperPanelList>
        </DashboardRightPane>
      </DashboardWrapper>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ saveDepartment, departmentPicker }, dispatch);

const mapStateToProps = (state) => state.audioPlayer;

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
