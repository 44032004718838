import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Libraries
import moment from "moment";
import { BehaviorSubject, combineLatest } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import times from "lodash/times";


// styles
import {
    WrapperBackButton,
    TimeDetailPaginatorContainer,
    WrapperMenuOptions,
    ExecutiveManagementContainerCenter
} from "../../components/ExecutiveDetail/styles/GeneralDetailCardStyles";
import { InnerViewLayout, PseudoTable, Paginator, Loader } from "../../../../components";


// Components
import { Heading, Back, PseudoDatePicker, Options, Informative } from "hooly-ui-kit";
import ManagementDetailCard from "../../components/ExecutiveDetail/components/ManagementDetailCard";
import ExecutiveLeadRecordings from "./ExecutiveLeadRecordings";
import ExecutiveCardSkeleton from "../../components/ExecutiveCardSkeleton/ExecutiveCardSkeleton";

// Assets
import rayo from './../../../../assets/images/Rayo.svg';

// Actions
import {
    openLateralMenu,
    closeLateralMenu,
    openBackdrop,
    closeBackdrop,
    addNotification
} from "../../../../redux/actions";

// Services
import { getSearchManagedStagenameByExecutive } from "../../../../services/index";

const headerOptions = [
    {
        title: "Fecha",
        function: null
    },
    {
        title: "Teléfono",
        function: null
    },
    {
        title: "Gestión",
        function: null
    },
    {
        title: ""
    }
];

const menuOptions = [
    {
        displayText: "Abierto",
        value: "Abierto"
    },
    {
        displayText: "Agendado",
        value: "Agendado"
    },
    {
        displayText: "Cerrado",
        value: "Cerrado"
    },
    {
        displayText: "Contactado",
        value: "Contactado"
    },
    {
        displayText: "Ganado",
        value: "Ganada"
    },
    {
        displayText: "No Contactado",
        value: "No Contactado"
    },
    {
        displayText: "Perdido",
        value: "Perdida"
    }
];

const itemsPerPage = 6;

class ExecutiveManagementDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.match.params.username,
            executiveStatusDetail: [],
            currentMenuOption: "Ganado",
            totalPage: 0,
            totalElementCount: 0,

            currentPage: 1,

            datesFilter: [],

            loadingExecutiveStatusDetail: true
        }

        this.paginator$ = new BehaviorSubject(1);
        this.options$ = new BehaviorSubject("Ganada")
        this.dates$ = new BehaviorSubject([]);
    }

    componentDidMount() {
        this.queryParamsCombination$ = combineLatest(
            this.paginator$,
            this.dates$,
            this.options$
        )
            .pipe(
                tap(([paginator, dates, options]) =>
                    this.setState({
                        loadingExecutiveStatusDetail: true,
                    })
                ),
                debounceTime(500)
            )
            .subscribe(([paginator, dates, options]) => {
                const { username } = this.state;
                const onlyRun = username.substring(0, username.length - 1);
                if (paginator || dates) {
                    const first_date = moment(dates[0]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
                    const end_date = moment(dates[1]).format("DD-MM-YYYY"); // get "yy-mm/yyyy"
                    getSearchManagedStagenameByExecutive(
                        first_date,
                        end_date,
                        onlyRun,
                        options,
                        itemsPerPage,
                        paginator
                    ).then(succ => {
                        const {
                            result_lead,
                            total_element_count,
                            total_page
                        } = succ.data;
                        this.setState({
                            loadingExecutiveStatusDetail: false,
                            executiveStatusDetail: result_lead,
                            totalPage: total_page,
                            totalElementCount: total_element_count
                        })
                    })
                }
            })
    }

    /**
     * When a paginator page is clicked, change the currentPage property of the state in order
     * to modify the Subject value and re-render the paginator with the new page
     */
    onPageClicked = pageNumber => {
        this.setState({ currentPage: pageNumber }, () =>
            this.paginator$.next(pageNumber)
        );
    };

    onOpenRecordings = (lead_id, phone_number, username) => {
        this.props.openBackdrop();

        this.props.openLateralMenu(
            <ExecutiveLeadRecordings
                lead_id={lead_id}
                phone_number={phone_number}
                username={username}
            />,
            "Detalle llamadas"
        );
    };

    componentWillUnmount() {
        /**
         * KILL ALL ASYNC AND RXJS OPERATIONS
         */
        this.queryParamsCombination$.unsubscribe();
        this.dates$.unsubscribe();
        this.paginator$.unsubscribe();
        this.options$.unsubscribe();
    }

    render() {
        const { history } = this.props;
        const {
            currentPage,
            currentMenuOption,
            totalElementCount,
            totalPage,
            executiveStatusDetail,
            loadingExecutiveStatusDetail,
            username
        } = this.state;

        const maxDate = moment().toDate();
        const minDate = moment("20190101", "YYYYMMDD").toDate();

        return (
            <InnerViewLayout>
                <InnerViewLayout.SearchbarAndOptions>
                    <WrapperBackButton>
                        <Back onClick={() => history.goBack()} size="m" />
                    </WrapperBackButton>
                    <Heading type="H2">Gestión</Heading>
                    <WrapperMenuOptions>
                        <Options
                            className="Options"
                            menuOptions={menuOptions}
                            selectedOption={currentMenuOption}
                            onOptionClicked={option => {
                                this.setState({ currentMenuOption: option }, () => {
                                    this.options$.next(option)
                                })
                            }}
                        />
                    </WrapperMenuOptions>

                    <PseudoDatePicker 
                        onDateSelected={range => {
                            this.setState({ datesFilter: range }, () =>
                                this.dates$.next(range)
                            );
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </InnerViewLayout.SearchbarAndOptions>
                <PseudoTable headers={headerOptions}>
                    {loadingExecutiveStatusDetail && times(itemsPerPage, num => <ExecutiveCardSkeleton key={num} numColumns={headerOptions.length} />) || (
                            executiveStatusDetail.length > 0 && (
                                executiveStatusDetail.map((value, index) => (
                                    <ManagementDetailCard
                                        key={index}
                                        timeSet={value}
                                        recordings={(lead_id, phone_number) => this.onOpenRecordings(lead_id, phone_number, username)}
                                    />))
                            )
                    )}

                </PseudoTable>

                {executiveStatusDetail.length == 0 && !loadingExecutiveStatusDetail && (
                    <ExecutiveManagementContainerCenter>
                        <Informative image={rayo} title="Ups! no hay resultados para tu búsqueda 😢" />
                    </ExecutiveManagementContainerCenter>
                ) || (
                        <TimeDetailPaginatorContainer>
                            <p>{`${currentPage * itemsPerPage -
                                (itemsPerPage - 1)} a ${currentPage *
                                itemsPerPage} de ${totalElementCount} Resultados`}</p>
                            <Paginator
                                totalPages={totalPage}
                                currentPage={currentPage}
                                beforePressed={newPage => this.onPageClicked(newPage)}
                                followingPressed={newPage => this.onPageClicked(newPage)}
                            />
                        </TimeDetailPaginatorContainer>
                    )}
            </InnerViewLayout>
        );
    }
};


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            openLateralMenu,
            closeLateralMenu,
            openBackdrop,
            closeBackdrop,
            addNotification
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(ExecutiveManagementDetail);
