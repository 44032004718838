const INITIAL_STATE = {
  isDeployed: false,
  title: null,
  componentToRender: null
};

const lateralMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "OPEN_LATERAL_MENU":
      const { title, componentToRender } = action.payload;

      return {
        isDeployed: true,
        componentToRender,
        title
      };
    case "CLOSE_LATERAL_MENU":
      return {
        isDeployed: false,
        title: null,
        componentToRender: null
      };
    case "CHANGE_LATERAL_MENU_COMPONENT_TO_RENDER":
      return { componentToRender };
    default:
      return state;
  }
};

export default lateralMenuReducer;
