import styled from "styled-components";
import { rem } from "polished";

export const RecordingsWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const RecordingsContainer = styled.div`
  position: relative;
`;

export const RecordingsPaginatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: 800;
    color: #6236ff;
    font-size: ${rem("14px")};
  }
`;

export const RecordginsHolder = styled.div``;

export const RecordingsContainerCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
`;

const DashboardPane = styled.section`
  width: 100%;
`;

export const WrapperAudioPlayer = styled(DashboardPane)`
  margin-bottom: ${rem("24px")};
`;