import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Containers (Views)
import { Dashboard, Login } from "./containers";

// Components
import { Backdrop, LateralMenu, NotificationsHolder } from "./components";

// Actions
import { closeLateralMenu, closeBackdrop } from "./redux/actions";

import { ProtectedRouteHOC } from "./utils/HOC/protectedRoute";

class App extends React.Component {
  render() {
    const {
      backdrop,
      lateralMenu,
      closeLateralMenu,
      closeBackdrop,
      notifications,
    } = this.props;

    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route path="/login" component={Login} />
            <ProtectedRouteHOC
              {...this}
              path="/dashboard"
              component={Dashboard}
            />
            <Route component={Login} />
          </Switch>
        </Router>
        <Backdrop isDeployed={backdrop.isDeployed} />
        <LateralMenu
          isDeployed={lateralMenu.isDeployed}
          onClosePressed={() => {
            closeBackdrop();
            closeLateralMenu();
          }}
          title={lateralMenu.title}
          contentToRender={lateralMenu.componentToRender}
        />
        <NotificationsHolder notifications={notifications} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { backdrop, lateralMenu, notifications } = state;

  return { backdrop, lateralMenu, notifications };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeLateralMenu,
      closeBackdrop,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
