// Services
import { NotificationsService } from "../../services";

const defaultState = {
  notifications: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case "ADD_NOTIFICATION":
      // COMMENT
      NotificationsService.registerNotification(action.payload.notification);

      return {
        ...state,
        notifications: state.notifications.concat(action.payload.notification)
      };
    case "REMOVE_NOTIFICATION":
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.id !== action.payload
        )
      };
    default:
      return state;
  }
};
