import * as React from "react"
import {Redirect, Route } from "react-router-dom";
import { getLocalStorageCognitoSession } from "../../services/cusAxios";
import { isUserAudit } from "../validators/roleValidator";
import { isLoged } from "../../services/authServ";
import jwt_decoded from "jwt-decode";

export const ProtectedRouteHOC = ({component, ...rest}) => {
  const renderFn = (Component) => (props) => {
    if (!Component)
      return null;

    if(isLoged) {

      const session = getLocalStorageCognitoSession();
      const decoded = jwt_decoded(session.token);

      const audit_path = '/dashboard/grabaciones';

      if (isUserAudit(decoded['cognito:groups']) && props.history.location.pathname !== audit_path)
        props.history.push('/dashboard/grabaciones');

      return <Component {...props} />;
    }

    const redirectProps = {
      to: {
        pathname: "/login",
        state: {from: props.location},
      },
    }

    return <Redirect {...redirectProps} />
  }

  return <Route {...rest} render={renderFn(component)} />
}