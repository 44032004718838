import * as React from "react";

import {
    StyledSearchBar,
    StyledSearchBarIcon,
    StyledSearchBarInput
} from "./styles/StyledSearchBar";

export default class SearchBar extends React.Component {
    render() {
        const { placeholder, onChange, className } = this.props;

        return (
            <StyledSearchBar className={className}>
                <StyledSearchBarIcon>
                    <i className="hly-magnifier" />
                </StyledSearchBarIcon>
                <StyledSearchBarInput
                    {...this.props}
                    placeholder={placeholder}
                    onChange={event => onChange(event.currentTarget.value)}
                />
            </StyledSearchBar>
        );
    }
}
